// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import { DashboardRange } from "./utilities";
import i18n from "i18next";

export const webConfigJSON = require("./config.js");
export interface ApiOrderItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    placed_at: string | null;
    product_name: string;
    product_image: string;
    order_id: string;
    price: string;
    status: string;
    item_status: string;
    date: string;
    delivery_address: string | null;
  };
}

export type Order = {
  id: string
  orderId: string
  name: string
  image: string
  address: string
  date: string
  payment: string
  total: number
  status: string
}

export type RevenueStats = {
  weekday: string,
  value: number
}[]

export type EarningStats = {
  taxes: number
  fees: number
  income: number
  total: number
  balance: number
}

export const configJSON = require("./config.js");

export interface Props {
  navigation: {
    navigate: (to: string, params: Object) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
}

type MenuArrayType = {
  id: number,
  name: string
}
interface S {
  orderData: {
    orderCount: number
    growthPercentage: number
  },
  earningData: {
    balance: number
    fees: number
    taxes: number
    income: number
    growthPercentage: number
  }
  revenueData: {
    revenue: number;
    revenueGrowthPercentage: number;
    data: Array<{
      weekday: string;
      revenue: number;
    }>;
  }
  orders: Order[]
  editOrderItem: Order | null
  token: string;
  errorMsg: string;
  roleInfoData: MenuArrayType[],
  roleType: string;
  language: string;
  loader: boolean;
  headerColumns: {}[],
  selectedRange: DashboardRange
}
interface SS {
  id: string;
}

export default class VendorDashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  revenueApiCallId: string = "";
  orderListApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
    ];

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      orders: [],
      orderData: {
        orderCount: 0,
        growthPercentage: 0
      },
      earningData: {
        balance: 0,
        fees: 0,
        taxes: 0,
        income: 0,
        growthPercentage: 0
      },
      revenueData: {
        revenue: 0,
        revenueGrowthPercentage: 0,
        data: [],
      },
      editOrderItem: null,
      errorMsg: "",
      token: "",
      roleInfoData: [],
      roleType: "",
      language: "en",
      loader: true,
      headerColumns: [],
      selectedRange: "last_week"
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  callGetApi = (
    apiIdField:
      "apiDashboardItemCallId"
      | "revenueApiCallId"
      | "orderListApiCallId",
    apiEndPoint: string
  ) => {
    const headers = {
      language: this.state.language,
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };

    const apiGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this[apiIdField] = apiGetMessage.messageId;
    const queryString = apiIdField === "orderListApiCallId" ? "?page=1&per_page=3" : `?duration_type=${this.state.selectedRange}`
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}${queryString}`
    );

    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
  };

  fetchData = (isRangeChanged?: boolean) => {
    this.callGetApi("apiDashboardItemCallId", webConfigJSON.vendorDashboardApiEndPoint)
    this.callGetApi(
      "revenueApiCallId",
      webConfigJSON.dashboardRevenueApiEndPoint
    );
    if (!isRangeChanged) {
      this.callGetApi("orderListApiCallId", webConfigJSON.orderListVendorApiEndPoint)
    }
  }

  formatPercentage = (inputPercentage: number | string, withPrefix?: boolean) => {
    const percentage = Number(inputPercentage)
    const prefix = withPrefix && percentage > 0 ? "+" : ""
    let formatted = percentage.toFixed(2)
    formatted = parseFloat(formatted).toString()
    const [integer, decimal] = formatted.split(".")
    return `${prefix}${decimal ? formatted : integer}%`;
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (selectedLng != undefined && selectedLng != this.state.language) {
        this.setState({ language: selectedLng }, () => {
          languageConvertor(selectedLng);
          this.handleLoader();
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.receiveApiResponse(message)
    }
  }

  receiveApiResponse = async (message: Message) => {
    const resJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiCallId = await message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiCallId === this.apiDashboardItemCallId && resJson?.data) {
      this.setState({
        orderData: {
          orderCount: resJson.data.vendor_orders_details.order_count,
          growthPercentage: resJson.data.vendor_orders_details.growth_percentage
        },
        earningData: {
          balance: parseFloat(resJson.data.vendor_earnings_data.balance),
          fees: parseFloat(resJson.data.vendor_earnings_data.total_fees),
          taxes: parseFloat(resJson.data.vendor_earnings_data.total_taxes),
          income: parseFloat(resJson.data.vendor_earnings_data.total_income),
          growthPercentage: resJson.data.vendor_earnings_data.growth_percentage_earning
        },
      })
    }
    if (apiCallId === this.revenueApiCallId && !resJson.errors?.length) {
      this.setState({
        revenueData: {
          revenue: parseFloat(resJson?.total_amount) || 0,
          revenueGrowthPercentage: 0,
          data: resJson.data?.map(
            (item: {
              day: string;
              date: string;
              month?: string;
              total_sales: string;
            }) => ({
              weekday: item.month?.substring(0, 3) || item.date,
              revenue: parseFloat(item.total_sales),
            })
          ),
        },
      });
    }
    if (apiCallId === this.orderListApiCallId) {
      this.setState({
        orders: resJson?.data?.map((item: ApiOrderItem) => ({
          id: item.id,
          orderId: item.attributes.order_id,
          name: item.attributes.product_name,
          image: item.attributes.product_image,
          address: item.attributes.delivery_address || "",
          total: this.formatArabicCurrency(parseFloat(item.attributes.price) || 0, 2),
          date: item.attributes.date,
          status: item.attributes.status,
          payment: i18n.t("DASHBOARD.PAIDTXT")
        })) || []
      })
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    const [token, language] = await Promise.all([getStorageData('authToken'), getStorageData('language')]);
    this.setState({ language: language || "en", token }, () => {
      this.fetchData()
      languageConvertor(language || "en");
      this.handleLoader();
    });
  }

  goToOrderList = () => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OrderListVendor"
    );
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
  }

  handleLoader = () => {
    this.setState({
      loader: false, headerColumns: [
        { id: 'orderId', label: i18n.t('DASHBOARD.TABLEHEADER.ORDERID') },
        { id: 'productName', label: i18n.t('DASHBOARD.TABLEHEADER.PRODUCTNAME'), renderFunction: 'renderRowHaveImage', imageColumn: 'image' },
        { id: 'date', label: i18n.t('DASHBOARD.TABLEHEADER.DATE') },
        { id: 'payment', label: i18n.t('DASHBOARD.TABLEHEADER.PAYMENTS'), renderFunction: 'renderPaymentStatus' },
        { id: 'total', label: i18n.t('DASHBOARD.TABLEHEADER.TOTAL') },
        { id: 'status', label: i18n.t('DASHBOARD.TABLEHEADER.STATUS'), renderFunction: 'renderRowStatus' }
      ]
    })

  }

  formatArabicCurrency = (inputNumber: number, fractionDigits?: number) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      maximumFractionDigits: fractionDigits || 2,
      minimumFractionDigits: fractionDigits || 0,
      currency: "SAR",
    }).format(inputNumber);

  handleOpenEditOrder = (rowItem: Order) => this.setState({ editOrderItem: rowItem })

  closeEditOrder = () => this.setState({ editOrderItem: null })

  handleUpdateSuccess = (updatedItem: { id: string; status: string }) => {
    this.setState(prevState =>
    ({
      orders: prevState.orders.map(order => order.id === updatedItem.id ? ({ ...order, status: updatedItem.status }) : order),
      editOrderItem: null
    }))
  }

  handleChangeRange = (newRange: DashboardRange) => this.setState({ selectedRange: newRange }, () => this.fetchData(true))

}
// Customizable Area End
