import React from "react";

// Customizable Area Start
import {
  Box,
  Modal,
  Typography,
  Button,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DataTable from "../../../components/src/DataTable.web";
import GenericTab from "../../../components/src/GenericTab.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ImportExportData from "../../importexportdata/src/ImportExportData.web";
import i18n from "i18next";
const CUSTOMER_LIST_SORTED_FIELD = [{ label: "Company", field: "company_name" }, { label: "Email", field: "email" }, { label: "Phone", field: "phone_number" }, { label: "City", field: "city"}, { label: "Status", field: "status"}]
import SortingTable from "../../../components/src/SortingTable.web";

// Customizable Area End

import CustomerListController, {
  Props,
} from "./CustomerListController";
import EditCustomer from "./EditCustomer.web";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class CustomerListBlock extends CustomerListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      // Required for all blocks
      <NavigationMenu id="" navigation={this.props.navigation} pageBackgroundMode="light" handleSearch={this.handleSearchHeader}>
      <ThemeProvider theme={theme}>
          <CustomerListWrap>
            <Box className="titleWrap">
              <Box className="title">{i18n.t("CUSTOMERSTXT")}</Box>
              <Box id="customerFound">{this.state.totalCount} {i18n.t("CUSTOMERSFOUNDTXT")}</Box>
            </Box>

            <Box className="tabsWrap">
              <GenericTab currentTab={this.state.currentTab} onTabChange={this.handleTabChange.bind(this)} tabsData={this.state.tabsData} />
              <ImportExportData
                navigation={this.props.navigation}
                id=""
                exportCsvEndPoint={this.exportEndPoint()}
              />
              <SortingTable columnSorted={CUSTOMER_LIST_SORTED_FIELD} getSortValueFn={this.getSortValueFn} />
              <Box className="dateRangeWrap">
                <Box className="dateRangeSize">
                  <CustomDateRangePicker
                    displayDateFormat="MMM DD"
                    markingType="period"
                    hidePlaceHolder2
                    current=""
                    markedDates={
                      this.getMarkedDates(this.state.dateRange.fromDate, this.state.dateRange.toDate)
                    }
                    calenderContainer={{left: '-200px'}}
                    onDayPress={this.handleDateRangePickerDayPress}
                    onChange={this.handleDateRangePickerOnChange}
                  />
                </Box>
              </Box>
            </Box>
            <DataTable 
              data={this.state.tableData} 
              columns={this.state.customerColumns} 
              rowsPerPage={this.state.rowsPerPage} 
              onEditClick={this.openEditCustomer}
              onDeleteClick={this.openDeleteCustomerModal}
              paginationData={{
                currentPage: this.state.page,
                totalPages: this.state.totalPages,
                totalItemCount: this.state.totalCount
              }}
              onPageChange={this.handlePageChange}
             />
            {
              this.state.isOpenEdit && <EditCustomer defaultCustomer={this.state.customerDetail} isOpenModal={this.state.isOpenEdit} onCloseModal={this.closeEditCustomer} />
            }
            <ModalStyled
                    open={this.state.isOpenDeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={this.onCloseDeleteModal}
                    >
                      <Box className="deleteWrap">
                      <Typography variant="h6" component="h2">
                      {i18n.t("AREYOUSUEREDELETETHISTXT")}?
                      </Typography>
                      <Box sx={{mt: 2}}>
                        <Button onClick={this.onCloseDeleteModal} className="btnCancel">{i18n.t("NOTXT")}</Button>
                        <Button data-test-id={'btnDelete'} onClick={this.handleDelete} className="btnDelete">{i18n.t("YESTXT")}</Button>
                      </Box>
                    </Box>
                </ModalStyled>
          </CustomerListWrap>
          <CustomSnackBar
            data-test-id="CustomSnackBarHeader"
            open={this.state.showToast}
            errorMessage={this.state.toastMessage}
            onClose={() => this.handleCloseSnackBars()}
            severity={this.state.toastSuccess as "error" | "warning" | "info" | "success"}
          />
      </ThemeProvider>
</NavigationMenu> 
      // Customizable Area End
    );
  }
}

const CustomerListWrap = styled('div')({
   backgroundColor: 'white',
  paddingTop: '40px',
  paddingBottom: '30px',
  fontFamily: 'Roboto',
  "& .calenderContainer":{
    "@media (max-width: 420px)": {
      left: "-50px",
      width: "250px",
    },
  },
  "& .titleWrap": {
    display: 'flex',
    fontSize: 25,
    marginBottom: 20,
  },
  "& .title": {
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: '24px',
    letterSpacing: '2px'
  },
  "& #customerFound": {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '32px'
  },
  "& .tabsWrap": {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    "@media (max-width: 420px)": {
      flexDirection:"column",
      padding: "16px 10px",
    },
  },
  "& .dateRangeWrap": {
    borderRadius: 8,
    padding: "0px 15px 0px 35px",
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    "@media (max-width: 420px)": {
      width:"100%",
      padding: "0",
    },
  },
  "& .dateRangeSize": {
    maxWidth: 150,
    "@media (max-width: 420px)": {
      margin: "0 auto"
    },
  },
  "& .MuiPaper-elevation1": {
    boxShadow: 'none'
  },
});

const ModalStyled = styled(Modal)({
  "& .deleteWrap": {
    backgroundColor: '#fff',
    width: '300px',
    margin: '200px auto',
    textAlign: 'center',
    padding: '20px 20px 10px',
    },
  "& .btnCancel": {
    marginRight: '10px',
    backgroundColor: '#2563EB',
    color: 'white',
  },
  "& .btnDelete": {
    backgroundColor: 'red',
    color: 'white',
  },
});