import React from "react"
import { Box, styled } from "@material-ui/core"
import { PieChart, Pie } from "recharts";
import Typography from "./design-system/Typography.web";

type CustomDonutChartProps = {
    items: Array<{
        name: string
        fill: string
        value: number
    }>
}

const formatCurrency =
    new Intl.NumberFormat("en-US", {
        style: "currency",
        minimumFractionDigits: 0,
        currency: "SAR",
    }).format;

const CustomDonutChart = (props: CustomDonutChartProps) => {
    const { items } = props

    return (
        <CustomDonutChartWrapper>
            <PieChart
                className="donut-chart"
                height={120}
                width={120}
            >
                <Pie
                    data={items}
                    innerRadius={45}
                    outerRadius={60}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                />
            </PieChart>
            <Box display="flex" flexDirection='column' gridGap={10} >
                {
                    items.map(item => (
                        <Box key={item.name} display="flex" alignItems="center" gridGap={5} flexWrap='wrap'>
                            <Box display="flex" alignItems="center" gridGap={5}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                    <circle cx="6.05652" cy="5.50037" r="5.43396" fill={item.fill} />
                                </svg>
                                <Typography size="ms" color="charcoal" font="Roboto">
                                    {item.name}
                                </Typography>
                            </Box>

                            <Typography size="ms" color="dark" weight="bold" font="Inter">
                                {formatCurrency(item.value)}
                            </Typography>
                        </Box>
                    ))
                }
            </Box>
        </CustomDonutChartWrapper >
    )
}

const CustomDonutChartWrapper = styled(Box)({
    marginTop: 20,
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    "& .donut-chart": {
        flexShrink: 0
    },
})

export default CustomDonutChart