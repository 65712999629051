import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../utilities/src/create-request-message";
const {baseURL} = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  exportCsvEndPoint?: string;
  disabled?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  csvExportApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      token: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.csvExportApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.data?.url) this.exportFile(responseJson.data.url)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = async () => {
    const token = await getStorageData("authToken")
    this.setState({token: token || ""})
  };

  isJSON = (text: string) => {
    try {
      var json = JSON.parse(text);
      return typeof json === "object";
    } catch (error) {
      return false;
    }
  };

  exportFile = (fileUrl: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loadingCSV: false, loadingJson: false });
  };

  downloadCSVData = async () => {
    this.setState({ loadingCSV: true });
    const header = {
      "Content-Type": configJSON.csvApiContentType,
      token: this.state.token
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    const endPoint = `${baseURL}/${configJSON.exportEndPoint}/${this.props.exportCsvEndPoint}`
    this.csvExportApiCallId = requestMessage.messageId
    createRequestMessage({requestMessage, header, endPoint, method: configJSON.exportDataMethod})
  };

  downloadJsonData = () => {
    this.setState({ loadingJson: true });
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.state.token
    };

    const getJsonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jsonDataApiCallId = getJsonMessage.messageId;

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportDataEndpoint
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exportDataMethod
    );

    runEngine.sendMessage(getJsonMessage.id, getJsonMessage);
    return true;
  };
  // Customizable Area End
}
