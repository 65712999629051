// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import { DashboardRange } from "./utilities"

export const webConfigJSON = require("./config.js");

const generateColors = <T extends Object>(data: Array<T>) => {
  const hueStep = 360 / data.length;

  return data.map((item, index) => {
    const hueValue = Math.floor(index * hueStep);
    return {
      ...item,
      fill: `hsl(${hueValue}, 80%, 50%)`,
    };
  });
};

export interface Props {
  navigation: {
    navigate: (to: string, params: Object) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
}
interface S {
  token: string;
  loading: boolean;
  language: string;
  selectedRange: DashboardRange;
  dashboardData: {
    orders: number;
    products: number;
  };
  revenue: number;
  revenueGrowthPercentage: number;
  salesVolume: number;
  salesVolumeGrowthPercentage: number;
  salesRevenueData: Array<{
    weekday: string;
    revenue: number;
  }>;
  salesVolumeData: Array<{
    weekday: string;
    salesVolume: number;
  }>
  salesByCategory: Array<{
    name: string;
    fill: string;
    value: number;
  }>;
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  dashboardApiCallId: string = "";
  salesRevenueApiCallId: string = "";
  salesVolumeApiCallId: string = "";
  salesByCategoryApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      loading: false,
      language: "en",
      selectedRange: "last_week",
      dashboardData: {
        orders: 0,
        products: 0,
      },
      revenue: 0,
      revenueGrowthPercentage: 0,
      salesVolume: 0,
      salesVolumeGrowthPercentage: 0,
      salesRevenueData: [],
      salesVolumeData: [],
      salesByCategory: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const [token, storedLanguage] = await Promise.all([
      getStorageData("authToken"),
      getStorageData("language"),
    ]);
    const lang = storedLanguage || "en";
    languageConvertor(lang);
    this.setState({ language: lang, token }, () => {
      if (token) {
        this.fetchData();
      }
    });
  }

  callGetApi = (
    apiIdField:
      | "dashboardApiCallId"
      | "salesRevenueApiCallId"
      | "salesVolumeApiCallId"
      | "salesByCategoryApiCallId",
    apiEndPoint: string
  ) => {
    this.setState({ loading: true });
    const headers = {
      language: this.state.language,
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };

    const apiGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this[apiIdField] = apiGetMessage.messageId;
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}?duration_type=${this.state.selectedRange}`
    );

    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
  };

  fetchData = () => {
    this.callGetApi(
      "dashboardApiCallId",
      webConfigJSON.adminDashboardApiEndPoint
    );
    this.callGetApi(
      "salesRevenueApiCallId",
      webConfigJSON.dashboardRevenueApiEndPoint
    );
    this.callGetApi(
      "salesVolumeApiCallId",
      webConfigJSON.salesVolumeApiEndPoint
    );
    this.callGetApi(
      "salesByCategoryApiCallId",
      webConfigJSON.salesByCategoryApiEndPoint
    );
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(
        getName(MessageEnum.InfoPageTitleMessage)
      );
      if (selectedLng != undefined && selectedLng != this.state.language) {
        languageConvertor(selectedLng);
        this.setState({ language: selectedLng }, () => this.fetchData());
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.receiveResponse(message)
    }
  }

  receiveResponse = async (message: Message) => {
    const resJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiCallId = await message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiCallId === this.dashboardApiCallId && resJson?.data) {
      this.setState({
        dashboardData: {
          products: resJson.data.product_count,
          orders: resJson.data.order_count,
        },
      });
    }
    if (apiCallId === this.salesRevenueApiCallId) {
      this.setState({
        salesRevenueData: resJson?.data?.map(
          (item: {
            day: string;
            date: string;
            month?: string;
            total_sales: string;
          }) => ({
            weekday: item.month?.substring(0, 3) || item.date,
            revenue: parseFloat(item.total_sales),
          })
        ) || [],
        revenue: parseFloat(resJson?.total_amount) || 0
      });
    }
    if (apiCallId === this.salesVolumeApiCallId) {
      this.setState({
        salesVolumeData: resJson?.data?.map(
          (item: {
            day: string;
            date: string;
            month?: string;
            total_sales: number;
          }) => ({
            weekday: item.month?.substring(0, 3) || item.date,
            salesVolume: item.total_sales,
          })
        ) || [],
        salesVolume: resJson?.total_amount || 0
      });
    }
    if (apiCallId === this.salesByCategoryApiCallId) {
      const salesByCategoryArray = resJson?.data?.map(
        (category: { category: string; total_sales: number }) => ({
          name: category.category,
          value: category.total_sales,
        })
      ) || [];
      this.setState({
        salesByCategory: generateColors(salesByCategoryArray),
      });
    }
  }

  formatArabicCurrency = (inputNumber: number, fractionDigits?: number) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      maximumFractionDigits: fractionDigits || 2,
      minimumFractionDigits: fractionDigits || 0,
      currency: "SAR",
    }).format(inputNumber || 0);

  formatPercentage = (percentage: number) => {
    const prefix = percentage > 0 ? "+" : ""
    let formatted = percentage.toFixed(2)
    formatted = parseFloat(formatted).toString()
    const [integer, decimal] = formatted.split(".")
    return `${prefix}${decimal ? formatted : integer}%`;
  }

  handleChangeRange = (newRange: DashboardRange) => this.setState({ selectedRange: newRange }, () => this.fetchData())
}
// Customizable Area End
