Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InventoryManagement";
exports.labelBodyText = "InventoryManagement Body";
exports.InventoryEndPoint = "bx_block_inventory_management/products";
exports.inventoryListExportEndPoint = "inventory_list"

exports.createInventoryApiEndpoint = "bx_block_inventory_management/products";
exports.createInventoryApiMethodType = "POST";
exports.editInventoryApiEndpoint = "bx_block_inventory_management/products";
exports.editInventoryApiMethodType = "PUT";
exports.getCategoriesApiEndpoint = "bx_block_categories/categories";
exports.getCategoriesApiContentType = "application/json";
exports.getCategoriesApiMethodType = "GET";
exports.getProductsApiEndpoint = "catalogue/catalogues";
exports.getProductsApiContentType = "application/json";
exports.getProductsApiMethodType = "GET";

exports.btnExampleTitle = "CLICK ME";
exports.cancelBtnLabel = "Cancel"
exports.submitBtnLabel = "Submit"
// Customizable Area End
