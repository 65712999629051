import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
const Ulti = require("./../utility");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export type DateRange = {
  fromDate?: string;
  toDate?: string;
};
export interface VendorListingFromApi {
  id: string;
  attributes: {
    created_at: string;
    id: string;
    company_name: string;
    email: string;
    phone_number: string;
    location_string: string;
    activated: boolean;
    image: string;
    full_name: string;
  };
}
export type VendorTableData = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  date: string | Date;
  status: string;
  avatar: string;
  contact?: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  vendorRowsPerPage: number;
  vendorCurrentTab: number;
  vendorDateRange: DateRange;
  vendorTableData: Array<VendorTableData>;
  vendorRowEdit: object | null;
  apiToken: string | null;
  isOpenEdit: boolean;
  vendorDetail: VendorTableData;
  showToast: boolean;
  toastSuccess: string;
  toastMessage: string;
  isOpenDeleteModal: boolean;
  deleteId: string;
  textSearch: string;
  vendorTabsData: {}[];
  vendorColumns: {}[];
  language: string;
  sortType: string;
  page: number;
  totalPages: number;
  totalCount: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class VendorListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  vendorData: {}[] = [];
  vendorTabsData: string[] = [];
  vendorColumns: string[] = [];
  vendorMapDataTab: string[] = [];
  vendorPagination: object = {};
  vendorDateFormat: string;
  getVendorListCallId: string = "";
  deleteVendorCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.vendorDateFormat = "YYYY-MM-DD";

    this.state = {
      vendorRowsPerPage: 10,
      vendorCurrentTab: 0,
      vendorDateRange: {
        fromDate: moment().startOf("week").format(this.vendorDateFormat),
        toDate: moment().endOf("week").format(this.vendorDateFormat),
      },
      vendorRowEdit: null,
      vendorTableData: [],
      apiToken: "",
      isOpenEdit: false,
      vendorDetail: {
        id: "",
        name: "",
        email: "",
        phoneNumber: "",
        date: "",
        status: "",
        avatar: "",
        contact: "",
      },
      showToast: false,
      toastMessage: "",
      toastSuccess: "",
      isOpenDeleteModal: false,
      deleteId: "",
      textSearch: "",
      vendorTabsData: [],
      vendorColumns: [],
      language: "en",
      sortType: "",
      page: 1,
      totalPages: 0,
      totalCount: 0,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getToken();
    super.componentDidMount();
    const landing_lang = (await getStorageData("language")) || "en";
    this.handleChangeLanguageVendor(landing_lang);
    // Customizable Area End
  }
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.textSearch !== "" && this.state.textSearch !== prevState.textSearch) {
      this.getVendorList()
    }
    if(prevState.language !== this.state.language){
      this.handleChangeLanguageVendor(this.state.language)
    }
    if(this.state.sortType !== prevState.sortType ){
      this.getVendorList()
    }
  }

  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateArray = [];
    if (!toDate) {
      dateArray = [fromDate];
    } else {
      let iter = fromDate;
      const endDate = moment(toDate)
        .add(1, "day")
        .format(this.vendorDateFormat);
      while (iter !== endDate) {
        dateArray.push(iter);
        iter = moment(iter).add(1, "day").format(this.vendorDateFormat);
      }
    }
    let result: { [key: string]: Object } = {};
    dateArray.forEach((date, index) => {
      result[date] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleTabChange = (value: number) => {
    this.setState({ vendorCurrentTab: value, page: 1 }, () =>
      this.getVendorList()
    );
  };

  handleDateRangePickerDayPress = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.vendorDateRange;
    let newDateRange = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRange = { fromDate: dateString };
    } else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate)
        ? {
            fromDate: moment(dateString).format(this.vendorDateFormat),
            toDate: fromDate,
          }
        : {
            fromDate,
            toDate: moment(dateString).format(this.vendorDateFormat),
          };
    }
    this.setState({ vendorDateRange: newDateRange });
    if (!newDateRange.hasOwnProperty("toDate")) {
      newDateRange = {
        ...newDateRange,
        toDate: dateString,
      };
    }
    this.getVendorList(newDateRange);
  };

  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      vendorDateRange: data,
    });
  };

  getTabStatus = () => {
    return Ulti.mapDataTab[this.state.vendorCurrentTab];
  };

  handleVendortableData(CustomerArrayData: VendorListingFromApi[]) {
    const newData = CustomerArrayData.map((item: VendorListingFromApi) => {
      const createdAtDate = new Date(item.attributes.created_at);
      const formattedDate = `${createdAtDate.getFullYear()}-${(
        createdAtDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${createdAtDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      return {
        id: item.id,
        name: item.attributes.company_name,
        email: item.attributes.email,
        phoneNumber: item.attributes.phone_number,
        date: formattedDate,
        status: item.attributes.activated ? 'Active' : 'Inactive',
        avatar: item.attributes.image,
        contact: item.attributes.full_name,
      }
    })
    this.setState({ vendorTableData: newData })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguageVendor(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await getStorageData("authToken");
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getVendorList();
        });
      }
    }
    const editVendorResponse: VendorListingFromApi= message?.properties?.RestAPIResponceSuccessMessage?.data
    if(editVendorResponse && editVendorResponse.id && editVendorResponse.attributes) {
     this.updateTableData(editVendorResponse)
     this.setState({
      showToast: true,
      toastSuccess: "success",
      toastMessage: i18n.t('UPDATED_SUCCESSFULLY_TXT'),
     })
    }

    const resJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      resJson.message === "Account deleted successfully!"
    ) {
      this.removeItem(this.state.deleteId);
      this.onCloseDeleteModal();
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVendorListCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = await message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorResponse) {
        this.parseApiCatchErrorResponse(errorResponse);
      }
      this.setState({
        totalPages: resJson?.meta?.pagination.total_pages || 0,
        totalCount: resJson?.meta?.pagination.total_count || 0,
      });
      this.handleVendortableData(responseJson?.data ?? []);
    }
    // Customizable Area End
  }
  getVendorList = (dateRange?: DateRange) => {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getVendorlistApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorListCallId = requestMessage.messageId;
    let fromDate = this.state.vendorDateRange.fromDate;
    let toDate = this.state.vendorDateRange.toDate;
    if (dateRange && (dateRange as {}).hasOwnProperty('toDate') && (dateRange as {}).hasOwnProperty('toDate')) {
      fromDate = dateRange?.fromDate;
      toDate = dateRange?.toDate;
    }
    const tabQueryString = this.state.vendorCurrentTab
      ? `&activated=${this.state.vendorCurrentTab === 1}`
      : "";
    const searchQuery = this.state.textSearch
      ? `&query=${this.state.textSearch}`
      : "";
    const enndPoint =
      `${configJSON.getVendorListEndPoint}&start_date=${fromDate}&end_date=${toDate}${searchQuery}${tabQueryString}&page=${this.state.page}&per_page=${this.state.vendorRowsPerPage}` +
      this.state.sortType;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      enndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getVendorListMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openEditVendor = (vendor: VendorTableData) => {
    this.setState({ isOpenEdit: true, vendorDetail: vendor })
  }
  closeEditVendor = () => {
    this.setState({
      isOpenEdit: false,
    });
  };
  updateTableData = (newVendor: VendorListingFromApi) => {
    const newState = this.state.vendorTableData.map((item) => {
      const attributesData = newVendor?.attributes;
      const createdAtDate = new Date(attributesData.created_at) ?? "";
      return item.id === newVendor.id ? {
        id: item.id,
        name: attributesData?.company_name,
        email: attributesData?.email,
        phoneNumber: attributesData?.phone_number,
        date: createdAtDate,
        status: attributesData?.activated ? 'Active' : 'Inactive',
        avatar: attributesData?.image,
        contact: attributesData?.full_name,
      } : item
    })
   
    this.setState({ vendorTableData: newState }, () => this.getVendorList())
   
  }
  handleCloseSnackBars() {
    this.setState({ showToast: false });
  }

  openDeleteVendorModal = (deleteId: string) => {
    this.setState({ isOpenDeleteModal: true, deleteId: deleteId })
  }

  handleDelete = () => {
    if (this.state.deleteId) {
      let deleteId = this.state.deleteId;
      const header = {
        token: this.state.apiToken,
        "Content-Type": configJSON.deleteVendorApiContentType,
      };

      const deleteVendorMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteVendorCallId = deleteVendorMsg.messageId;

      deleteVendorMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteVendorAPIEndPoint}${deleteId}`
      );

      deleteVendorMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      deleteVendorMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteVendorAPIMethod
      );

      runEngine.sendMessage(deleteVendorMsg.id, deleteVendorMsg);
    }
  };

  removeItem = (deleteId: string) => {
    //new logic: remove = change status to Inactive
    let tableData = this.state.vendorTableData.map((item) => {
      if (item.id === deleteId) {
        item.status = "Inactive";
      }
      return item;
    });
    this.setState({
      showToast: true,
      toastSuccess: "success",
      toastMessage: i18n.t("DELETED_SUCCESSFULLY_TXT"),
      vendorTableData: tableData,
    }, () => this.getVendorList());
  };

  onCloseDeleteModal = () => {
    this.setState({
      isOpenDeleteModal: false,
      deleteId: "",
    });
  };
  handleSearchVendor = (value?: string) => {
    this.setState({ textSearch: "" + value });
  };
  handleChangeLanguageVendor = (lang: string) => {
    languageConvertor(lang);
    this.setState({
      language: lang,
      vendorTabsData: [
        { label: i18n.t("ALLTXT") },
        { label: i18n.t("ACTIVETXT") },
        { label: i18n.t("INACTIVETXT") },
      ],
      vendorColumns: [
        {
          id: "companyName",
          label: i18n.t("COMPANY"),
          renderFunction: "renderCompanyNameVendor",
        },
        { id: "email", label: i18n.t("EMAILTXT") },
        { id: "phoneNumber", label: i18n.t("PHONENUMBERTXT") },
        { id: "city", label: i18n.t("CITYTXT") },
        {
          id: "status",
          label: i18n.t("STATUSTXT"),
          renderFunction: "renderRowStatus",
        },
      ],
    });
  };
  recieveLanguageVendor = (vendorMessage: Message) => {
    if (vendorMessage.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = vendorMessage.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        })
      }
    }
  };
  getSortValueFn = (fieldName: string, sortType: string) => {
    this.setState({ sortType: sortType });
  };
  exportEndPoint = () => {
    const { fromDate, toDate } = this.state.vendorDateRange;
    const tabQueryString = this.state.vendorCurrentTab
      ? `&activated=${this.state.vendorCurrentTab === 1}`
      : "";
    const searchQueryString = this.state.textSearch
      ? `&query=${this.state.textSearch}`
      : "";
    return `${
      configJSON.exportVendorListEndPoint
    }&start_date=${fromDate}&end_date=${
      toDate || fromDate
    }${searchQueryString}${tabQueryString}`;
  };
  handlePageChange = (newPage: number) =>
    this.setState({ page: newPage }, () => this.getVendorList());
  // Customizable Area End
}
