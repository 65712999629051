// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
export const configJSON = require("./config.js");

export type SubCategory = {
  id: number;
  name: string;
};
export type ProductForm = {
  name: string;
  productCount: number;
};
export type Brand = ProductForm & {
  id: number;
};
export type ProductItem = {
  id: number | string;
  title: string;
  qty: string;
  price: string;
  image: string;
};

export interface Props {
  navigation: {
    navigate: (to: string, params: Object) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
}
interface S {
  categoryId?: number;
  sortBy: string;
  itemsPerPage: number;
  productItems: ProductItem[];
  subCategoriesArray: SubCategory[];
  brandsArray: Brand[];
  productFormsArray: ProductForm[];
  categoryName: string;
  selectedSubCategoryId?: number;
  selectedBrandIds: number[];
  selectedProducts: string[];
  token: string;
  errorMsg: string;
  loading: boolean;
  language: string;
  page: number
  totalPages: number
  totalCount: number
}
interface SS {
  id: string;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoryDetailApiCallId: string = "";
  filterProductsApiCallId: string = "";
  filterBrandsApiCallId: string = "";
  searchManufacturerListApiCallId: string = "";

  static sortByOptions = [
    {
      key: "relevance",
      name: "RELEVANCETXT",
    },
  ];

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      categoryId: this.getParam("navigationBarTitleText"),
      sortBy: CategoriessubcategoriesController.sortByOptions[0].key,
      itemsPerPage: 20,
      productItems: [],
      brandsArray: [],
      productFormsArray: [],
      categoryName: "",
      selectedSubCategoryId: this.getParam("subCategoryId"),
      subCategoriesArray: [],
      selectedBrandIds: [],
      selectedProducts: [],
      token: "",
      errorMsg: "",
      loading: false,
      language: "en",
      page: 1,
      totalPages: 0,
      totalCount: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const loglang = (await getStorageData("language")) || "en";
    this.setState({ language: loglang }, async () => {
      languageConvertor(loglang);
      await this.getToken();
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    const { token, selectedSubCategoryId, categoryId } = this.state;
    if (!prevState.token && token) {
      this.callGetCategoryDetailApi();
      this.callFilterBrandsApi();
      this.callSearchManufacturerApi();
      this.callFilterProductsApi();
    }

    const newCategoryId = this.getParam("navigationBarTitleText");
    const newSubCategoryId = this.getParam("subCategoryId");
    if (
      newCategoryId !== categoryId ||
      newSubCategoryId !== selectedSubCategoryId
    ) {
      this.setState({
        categoryId: newCategoryId,
        selectedSubCategoryId: newSubCategoryId,
      });
    }

    if (
      prevState.selectedSubCategoryId !== selectedSubCategoryId ||
      categoryId !== prevState.categoryId
    ) {
      if (prevState.categoryId !== categoryId) {
        this.callGetCategoryDetailApi();
      }
      this.callFilterBrandsApi();
      this.callSearchManufacturerApi();
      this.setState({ selectedBrandIds: [], selectedProducts: [] }, () =>
        this.callFilterProductsApi()
      );
    }
  }

  getParam(param: "navigationBarTitleText" | "subCategoryId") {
    const parsedParam = this.props.navigation.getParam(param, "");
    return /^\d+$/.test(parsedParam) ? Number(parsedParam) : undefined;
  }

  getToken = async () => {
    const token = (await getStorageData("authToken")) as string;
    this.setState({ token: token });
    return token;
  };

  getBaseQueryString = () =>
    `?category_id=${this.state.categoryId}${
      this.state.selectedSubCategoryId !== undefined
        ? `&sub_category_id=${this.state.selectedSubCategoryId}`
        : ""
    }`;

  goToHome() {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
  }

  handleChangeSubCategory(newSubCategoryId: number) {
    this.props.navigation.navigate("Categoriessubcategories", {
      navigationBarTitleText: this.state.categoryId,
      subCategoryId: newSubCategoryId,
    });
  }

  handleChangeBrands = (brands: number[]) =>
    this.setState({ selectedBrandIds: brands }, () =>
      this.callFilterProductsApi()
    );
  handleChangeProducts = (products: string[]) =>
    this.setState({ selectedProducts: products }, () =>
      this.callFilterProductsApi()
    );

  callGetApi = (
    apiIdField:
      | "getCategoryDetailApiCallId"
      | "filterProductsApiCallId"
      | "filterBrandsApiCallId"
      | "searchManufacturerListApiCallId",
    apiEndPoint: string
  ) => {
    if (this.state.categoryId === undefined) {
      return;
    }
      this.setState({ loading: true });
    const headers = {
      language: this.state.language,
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const apiGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this[apiIdField] = apiGetMessage.messageId;
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
  };

  callGetCategoryDetailApi() {
    this.callGetApi(
      "getCategoryDetailApiCallId",
      `${configJSON.categoryAPIEndPoint}/${this.state.categoryId}`
    );
  }

  callFilterProductsApi(isChangePage?: boolean) {
    const brandQueryString =
      this.state.selectedBrandIds.length > 0
        ? `&brand_id=${this.state.selectedBrandIds.join(",")}`
        : "";
    const manufacturerQueryString =
      this.state.selectedProducts.length > 0
        ? `&manufacturer_name=${this.state.selectedProducts.join(",")}`
        : "";
    const queryString =
      this.getBaseQueryString() + brandQueryString + manufacturerQueryString;

    this.callGetApi(
      "filterProductsApiCallId",
      configJSON.filterProductsApiEndpoint + queryString + `&page=${isChangePage ? this.state.page : 1}&limit=${this.state.itemsPerPage}`
    );
    if(this.state.categoryId==34){
      this.callGetApi(
        "filterProductsApiCallId",
        configJSON.filterProductsApiEndpoint
      )
    }
  }

  callFilterBrandsApi() {
   if(this.state.categoryId==35){
        this.callGetApi(
      "filterBrandsApiCallId",
      configJSON.filterBrandsApiEndpoint
    );
   }
  }

  callSearchManufacturerApi() {
    this.callGetApi(
      "searchManufacturerListApiCallId",
      configJSON.searchManufacturerApiEndpoint + this.getBaseQueryString()
    );
  }

  async receive(from: string, message: Message) {
    this.recieveLanguage(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.receiveApiResponseMessage(message);
    }
  }
  recieveLanguage = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({ language: lang }, () => {
          this.callGetCategoryDetailApi();
          languageConvertor(lang);
        });
      }
    }
  };

  formatProductItemData = (record: {
    id: number;
    attributes: { images: Array<{ url: string }> } & {
      [key: string]: string | number | null;
    };
  }) => ({
    id: record.id,
    title: record.attributes.name_locale,
    qty: record.attributes.block_qty
      ? `${record.attributes.block_qty} unit`
      : "",
    price: `SAR${record.attributes.price}`,
    image: record.attributes.images?.length
      ? record.attributes.images[0].url
      : "",
  });

  receiveApiResponseMessage = async (message: Message) => {
    let updateStateData: Partial<S> = {
      loading: false,
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.parseApiCatchErrorResponse(errorReponse);
    switch (apiRequestCallId) {
      case this.getCategoryDetailApiCallId: {
        updateStateData.subCategoriesArray = responseJson?.data
          ? responseJson.data.attributes.sub_categories.data.map(
              (record: {
                attributes: {
                  id: number;
                  name: string;
                  name_locale: string;
                };
              }) => ({
                id: record.attributes.id,
                name: record.attributes.name_locale,
              })
            )
          : [];
        updateStateData.categoryName =
          responseJson?.data?.attributes?.name_locale;
        break;
      }
      case this.filterProductsApiCallId: {
        updateStateData.productItems = responseJson?.data
          ? responseJson.data.map(this.formatProductItemData)
          : [];
        updateStateData.page = responseJson?.meta?.pagination.current_page || 1
        updateStateData.totalPages = responseJson?.meta?.pagination.total_pages || 0
        break;
      }
      case this.filterBrandsApiCallId: {
        updateStateData.brandsArray = (responseJson?.data || []).map(
          (record: { id: number; name: string; product_count: number }) => ({
            id: record.id,
            name: record.name,
            productCount: record.product_count,
          })
        );
        break;
      }
      case this.searchManufacturerListApiCallId: {
        updateStateData.productFormsArray = (responseJson?.data || []).map(
          (record: { name: string; product_count: number }) => ({
            name: record.name,
            productCount: record.product_count,
          })
        );
        break;
      }
      default:
    }
    this.setState(updateStateData as Pick<S, keyof S>);
  };
  goToProductDetail = (productId: string | number) => {
    const request: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    request.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProductDescription"
    );
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    request.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      productId
    );

    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.InfoPageBodyMessage),
      this.state.categoryId
    );
    request.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(request);
  };
  goToCategory = () => {
    if (this.state.categoryId === undefined) return;
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Categoriessubcategories"
    );
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigationMessage.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.state.categoryId
    );
    this.send(navigationMessage)
  }
  
  currentSubCategoryName = () => this.state.subCategoriesArray.find(subCategory => subCategory.id === this.state.selectedSubCategoryId)?.name || ""

  getpwdstyle = () => {
    let space_pw =
      this.state.language === "ar"
        ? { padding: "18px 18px 18px 0px" }
        : { padding: "18px 0px 18px 18px" };
    return space_pw;
  };
  handlePageChange = (newPage: number) => this.setState(({page: newPage}), () => this.callFilterProductsApi(true))
}
// Customizable Area End
