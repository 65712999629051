import React, { Component } from "react";
import Context from "./Context";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";
import AppStateContextController from "./AppStateContextController";

const DEFAULT_LANGUAGE = "English";
const APP_LANGUAGE = "appLanguage";

class AppStateContext extends AppStateContextController {

  render() {
    const {initLanguage, setLanguageToAsyncStorage} = this;
    return (
      <Context.Provider
        value={{
          initLanguage,
          setLanguageToAsyncStorage,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default AppStateContext;
