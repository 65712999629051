import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton
  // Customizable Area Start
  , styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import i18next from "i18next";
import {exportCsvIcon} from "./assets"
// Customizable Area End

import ImportExportDataController, {
  Props,
  configJSON
} from "./ImportExportDataController.web";

export default class ImportExportData extends ImportExportDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainWrapper>
        <CustomButton
          themes="primary"
          display="textButton"
          isDisable={this.props.disabled || this.state.loadingCSV}
          data-test-id="downloadCsv"
          onClickHandle={() => this.downloadCSVData()}
        >
          <img src={exportCsvIcon} />
          {i18next.t('EXPORTTXT')}
        </CustomButton>
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  "& button.textButtonBtn": {
    color: "#64748B"
  }
})
// Customizable Area End
