import React, { Component, ReactElement } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Box,
  IconButton,
  Popover,
  styled,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import i18n from "i18next";
import CustomCheckbox from "../../components/src/design-system/CustomCheckbox.web";
import { imgInCome, imgOutCome } from '../src/assets'

type TableType = {
  id?: string;
  name: string;
  image?: string;
  avatar?: string;
  transactionType?: string;
  price?: number;
  date: string;
  status: string;
  contact: string;
  payment: string;
  total?: string;
  orderId?: string
};

type ColumnType = {
  id: string;
  label: string;
  renderFunction?: string;
  imageColumn?: string;
  mapStatusColumn?: object;
};
interface Props {
  data: any[];
  columns: any[];
  paginationData?: {
    currentPage: number;
    totalPages: number;
    totalItemCount: number;
  };
  rowsPerPage: number;
  onPageChange?: (newpage: number) => void;
  onEditClick?: (event: any) => void;
  onDeleteClick?: (event: any) => void;
  adminVendorOrder?: boolean
  hideContextMenu?: boolean
}

interface S {
  count: number,
  page: number,
  anchorEl: any;
}

interface SS {
}


export default class DataTable extends Component<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.state = {
      count: this.props.data.length,
      page: 0,
      anchorEl: null
    }

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.paginationData) {
      const {paginationData} = this.props
      if (paginationData.currentPage > 1 && paginationData.currentPage > paginationData.totalPages) {
        this.handleChangePage(null , Math.max(paginationData.totalPages - 1, 0))
      }
    }
  }

  render() {
    const { data, columns, rowsPerPage, hideContextMenu, paginationData } = this.props;
    const dataLenght =  paginationData ? paginationData.totalItemCount : data.length;
    const currentPageNumber = paginationData ? paginationData.currentPage - 1 : this.state.page
    let pageFrom = (currentPageNumber * rowsPerPage) + 1
    let pageTo = (currentPageNumber + 1) * rowsPerPage;
    if (pageTo > dataLenght) {
      pageTo = dataLenght
    }
    const renderData = paginationData ? data : data.slice(this.state.page * rowsPerPage, this.state.page * rowsPerPage + rowsPerPage)
    return (
      <DataTableWrapper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map((column) =>
                (
                  <TableCell key={column.id}>{column.renderFunction === 'renderRowWithCheckbox' ? this.renderCheckboxAll(column) : column.label}</TableCell>
                ))}
                {
                  !Boolean(hideContextMenu) && <TableCell />
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {renderData.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {this.renderRow(row, column)}
                        </TableCell>
                      );
                    })}
                    {
                      hideContextMenu ? null
                        :
                        <TableCell>
                          <IconButton data-test-id={"btnTableOpenPopover"} aria-controls="open-menu" id={row.id} onClick={this.openMenu}>
                            <MoreVertSharpIcon />
                          </IconButton>
                          <Box>
                            <StyledPopover
                              id={row.id}
                              open={Boolean(this.state.anchorEl) && this.state.anchorEl.id === row.id}
                              anchorEl={this.state.anchorEl}
                              onClose={this.closeMenu}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                            >

                              <Box data-test-id={"btnTableEdit"} className="actionIcon" onClick={() => { this.handleEditRow(null, row, this.props.onEditClick) }}>
                                <Box sx={{ mr: 1 }}>
                                  <EditIcon />
                                </Box>
                                {i18n.t("EDITTXT")}
                              </Box>
                              {
                                !this.props.onDeleteClick || this.props.adminVendorOrder ? "" : <Box data-test-id={'btnTableDelete'} className="actionIcon" onClick={() => { this.handleDeleteRow(null, row.id, this.props.onDeleteClick) }} sx={{ color: '#DC2626' }}>
                                  <Box sx={{ mr: 1 }}>
                                    <DeleteIcon />
                                  </Box>
                                  {i18n.t("DELETETXT")}
                                </Box>
                              }
                            </StyledPopover>
                          </Box>
                        </TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {dataLenght > rowsPerPage &&
          <Box className="pagination">
            <Box>{i18n.t("SHOWINGTXT")} {pageFrom} {i18n.t("TOTXT")} {pageTo} {i18n.t("OFTXT")} {dataLenght} {i18n.t("ENTRIESTXT")}</Box>
            <Pagination page={paginationData?.currentPage} onChange={(event, value) => this.handleChangePage(null, value - 1)} count={paginationData ? paginationData.totalPages : Math.ceil(dataLenght / rowsPerPage)} shape="rounded" />
          </Box>
        }
      </DataTableWrapper>
    );
  }

  handleChangePage = (event: unknown, newPage: number) => {
    this.setState(prev => ({ ...prev, page: newPage }))
    this.props.onPageChange?.(newPage + 1)
  };

  handleEditRow = (event: unknown, row: object, callback: any = '') => {
    this.setState(prev => ({ ...prev, rowEdit: row, anchorEl: null }))
    if (callback) {
      callback(row)
    }
  };

  handleDeleteRow = (event: unknown, rowId: string, callback?: (itemId: string) => void) => {
    this.setState({ anchorEl: null })
    if (callback) {
      callback(rowId)
    }
  };

  renderRow = (row: TableType, column: ColumnType) => {
    let text: ReactElement;
    if (column.renderFunction == 'renderRowId') {
      text = this.renderRowId(row)
    }
    else if (column.renderFunction == 'renderRowHaveImage') {
      text = this.renderRowHaveImage(row)
    }
    else if (column.renderFunction == 'renderRowWithCheckbox') {
      text = this.renderRowWithCheckbox(row, column)
    }
    else if (column.renderFunction == 'renderRowStatus') {
      text = this.renderRowStatus(row, column.mapStatusColumn ?? null)
    }
    else if (column.renderFunction == 'renderPaymentStatus') {
      text = this.renderPaymentStatus(row)
    }

    else if (column.renderFunction == 'renderRowInOutCome') {
      text = this.renderRowInOutCome(row)
    }
    else if (column.renderFunction == "renderRowTotal") {
      text = <Box>{row.total}</Box>
    }
    else if (column.renderFunction == "renderCompanyNameVendor") {
      text = <Box>{row.name}</Box>
    }
    else if (column.renderFunction === "renderOrderId") {
      text = <Box>{row.orderId}</Box>
    }
    else {
      text = <Box>{row[column.id as keyof TableType]}</Box>
    };

    return <Box fontWeight="fontWeightRegular">
      {text}
    </Box>
  }

  renderRowHaveImage = (row: TableType, imageColumn: string = 'avatar') => {
    let image = row[imageColumn as keyof object];
    return <Box display="flex" alignItems="center">
      <Box sx={{ mr: 1 }}>
        <Avatar alt={row.name} src={row.image || image} />
      </Box>
      <Box>
        {row.contact ? row.contact : row.name}
      </Box>
    </Box>
  };

  renderRowWithCheckbox = (row: TableType, column: ColumnType) => {
    return <Box className="checkboxWrap flex-center">
      <CustomCheckbox
        name="dataTableCheckbox"
        label=""
        checked={false}
        onValueChange={() => { }}
        id={row.id}
      />
      <Box>{row[column.id as keyof TableType]}</Box>
    </Box>
  };

  renderRowStatus = (row: TableType, mapStatusColumn: {} | null = null) => {
    let status = row.status;
    if (mapStatusColumn) {
      status = mapStatusColumn[status as keyof object];
    }
    let statusClass = 'class' + status;
    let iconClass = 'icon' + status;

    return <Box className={`baseClassStatus ${statusClass}`}>
      <Box className={`iconStatus ${iconClass}`} sx={{ mr: 1 }}></Box>
      <Box>{row.status}</Box>
    </Box >
  };

  renderPaymentStatus = (row: TableType, mapStatusColumn: {} | null = null) => {
    let payment = row.payment;
    if (mapStatusColumn) {
      payment = mapStatusColumn[payment as keyof object];
    }
    let statusClass = 'class' + payment;
    return <Box className={`paymentStatus${statusClass}`}>
      <Box>{row.payment}</Box>
    </Box >
  };

  renderRowInOutCome = (row: TableType) => {
    let elm: ReactElement;

    if (row.transactionType === 'Income') {
      elm = <Box className='flex-center green'>
        <img src={imgInCome} alt={row.transactionType} />
        <Box sx={{ ml: 1 }}>{row.transactionType}</Box>
      </Box >
    } else {
      elm = <Box className='flex-center red'>
        <img src={imgOutCome} alt={row.transactionType} />
        <Box sx={{ ml: 1 }}>{row.transactionType}</Box>
      </Box >
    }

    return elm
  };

  renderRowId = (row: TableType) => {
    let numberId = parseInt(row.id || '0')
    let text = '#' + this.addLeadingZeros(numberId)
    return <Box>{text}</Box>
  };

  renderCheckboxAll = (column: ColumnType) => {
    return <Box className="checkboxWrap flex-center">
      <CustomCheckbox
        name="checkAll"
        label=""
        checked={false}
        onValueChange={() => { }}
        id="dataTableCheckAll"
      />
      <Box>{column.label}</Box>
    </Box>
  };

  openMenu = (event: any) => {

    this.setState({ anchorEl: event.currentTarget });
    this.props.onEditClick
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  addLeadingZeros = (num: number) => {
    if (num < 10) {
      return '00' + num;
    } else if (num < 100) {
      return '0' + num;
    } else {
      return '' + num;
    }
  }

  renderRowPrice = (row: TableType) => {
    const formattedPrice = Number(row.price).toFixed(2);
    return <Box>{'SAR ' + formattedPrice}</Box>
  }
}

const DataTableWrapper = styled('div')({
  "& .classActive , .classDelivered , .classCompleted": {
    color: '#059669',
    '& .iconStatus': {
      borderColor: 'rgb(5, 150, 105, 0.2)',
      backgroundColor: '#059669',
    }
  },
  "& .classInactive , .classCancelled": {
    color: '#DC2626',
    '& .iconStatus': {
      borderColor: 'rgb(220, 38, 38, 0.2)',
      backgroundColor: '#DC2626',
    }
  },
  "& .classIn , .classPending": {
    color: '#F59E0B',
    '& .iconStatus': {
      borderColor: 'rgba(245, 158, 11, 0.2)',
      backgroundColor: '#F59E0B',
    }
  },
  "& .iconActive": {
    borderColor: '#7EDBBE',
    backgroundColor: '#059669',
  },
  "& .iconInactive": {
    borderColor: '#EA8282',
    backgroundColor: '#DC2626',
  },
  "& .iconStatus": {
    width: 8,
    height: 8,
    borderRadius: 25,
    border: 2,
    borderStyle: 'solid',
  },
  "& .baseClassStatus": {
    display: "flex",
    alignItems: "center",
    whiteSpace: 'nowrap',
  },
  "& .paymentStatusclassPaid": {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    whiteSpace: 'nowrap',
    justifyContent: "center",
    background: "#E5FFF6",
    color: "#059669",
    borderRadius: "4px",
    padding: "4px 16px",

  },
  "& .paymentStatusclassUnpaid": {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    whiteSpace: 'nowrap',
    justifyContent: "center",
    background: "#FFEBEB",
    color: "#DC2626",
    borderRadius: "4px",
    padding: "4px 16px"
  },
  "& .pagination": {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    padding: '20px 15px',
    backgroundColor: '#fff',
    fontSize: '14px',
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: '#C1D3F9',
      color: '#2563EB'
    },
    "& .MuiPagination-ul": {

      "@media (max-width: 420px)": {
        flexWrap: "nowrap",
      },
    }
  },
  "& .actionsContainer": {
    flexShrink: 0,
    marginLeft: 0,
  },
  "& .MuiTableCell-head": {
    whiteSpace: 'nowrap',
  },
  "& .checkboxWrap": {
    "& .checkbox-wrapper": {
      marginTop: "-3px",
    },
  },
  "& .flex-center": {
    display: "flex",
    alignItems: "center",
  },
  "& .red": {
    color: '#F87171'
  },
  "& .green": {
    color: '#34D399'
  },

});

const StyledPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    padding: '0px',
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid #E2E8F0',
    '& .actionIcon': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 60,
      paddingTop: 10,
      paddingBottom: 10,
      cursor: 'pointer'
    },
  },
});