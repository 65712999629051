import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
const Ulti = require("./../utility");
export const configJSON = require("./config");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export type DateRange = {
  fromDate?: string
  toDate?: string
}
export interface CustomerListingFromApi {
  id: string;
  attributes:{
    created_at: string;
    id: string;
    company_name: string;
    email: string;
    phone_number: string;
    location_string: string;
    activated: boolean
  }
}
export interface  CustomerListingInterface {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  city: string;
  date: string;
  status: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rowsPerPage: number;
  currentTab: number;
  dateRange: DateRange;
  tableData: CustomerListingInterface[];
  apiToken: string | null;
  customerListArray:CustomerListingInterface[],
  isOpenEdit: boolean,
  customerDetail: CustomerListingInterface,
  showToast:boolean
  toastSuccess:string
  toastMessage:string
  isOpenDeleteModal: boolean
  deleteId: string,
  textSearch: string,
  language: string;
  tabsData: {}[],
  customerColumns: {}[],
  sortType: string
  page: number
  totalPages: number
  totalCount: number
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomerListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  data: {}[] = [];
  columns: string[] = [];
  mapDataTab: string[] = [];
  pagination: object = {};
  dateFormat: string;
  getCustomerListCallId: string = "";
  deleteCustomerCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.dateFormat = 'YYYY-MM-DD'

    this.state = {
      rowsPerPage: 10,
      currentTab: 0,
      dateRange: {
        fromDate:  moment().startOf('week').format(this.dateFormat),
        toDate:  moment().endOf('week').format(this.dateFormat),
      },
      tableData: [],
      apiToken: "",
      customerListArray: [],
      isOpenEdit: false,
      customerDetail: {
        city: "",
        id:"",
        companyName:"",
        date:"",
        email:"",
        phoneNumber:"",
        status:""
      },
      showToast: false,
      toastSuccess:"",
      toastMessage:"",
      isOpenDeleteModal: false,
      textSearch:"",
      deleteId: '',
      language: 'en',
      tabsData: [],
      customerColumns: [],
      sortType:"",
      page: 1,
      totalPages: 0,
      totalCount: 0,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getTokenFn();
    super.componentDidMount();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageCustomer(landing_lang)
    // Customizable Area End
  }
  getTokenFn = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };
  componentDidUpdate(prevProps: Readonly<Props>, prev: Readonly<S>): void {
    if(prev.language !== this.state.language){
      this.handleChangeLanguageCustomer(this.state.language)
    }
    if(this.state.sortType !== prev.sortType ){
      this.getCustomerList()
    }
  }

  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) return {}
    let dateArray = []
    if (!toDate) dateArray = [fromDate]
    else {
      let iter = fromDate
      const endDate = moment(toDate).add(1, 'day').format(this.dateFormat)
      while (iter !== endDate) {
        dateArray.push(iter)
        iter = moment(iter).add(1, 'day').format(this.dateFormat)
      }
    }
    let result : {[key: string] : Object} = {}
    dateArray.forEach((date,index) => {
      result[date] = {
        ...index === 0 && { startingDay: true},
        color: "#E6EEFF",
        ...index === dateArray.length - 1 && {endingDay: true}
      }
    })
    return result;
  }

  handleTabChange = (value:number) => {
    this.setState({ currentTab: value, page: 1 }, () => this.getCustomerList());
  };

  handleDateRangePickerDayPress = ({ dateString }: {dateString: string}) => {
    const { fromDate, toDate } = this.state.dateRange
    let newDateRange = {}
    if (Boolean(fromDate) === Boolean(toDate))
      newDateRange = { fromDate: dateString }
    else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate) ? {
        fromDate: moment(dateString).format(this.dateFormat),
        toDate: fromDate
      } : {
        fromDate,
        toDate: moment(dateString).format(this.dateFormat)
      }
    }
    this.setState({dateRange: newDateRange})
    if (!newDateRange.hasOwnProperty("toDate")) {
      newDateRange = {
        ...newDateRange,
        toDate: dateString
      }
    }
    this.getCustomerList(newDateRange)
  }

  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      dateRange: data
    })
  }


  getTableData = (currentTab: string) => {
    let tableData = this.state.tableData;
    if(currentTab !== "All") {
      tableData = this.state.tableData.filter((data) => {
        return data.status == currentTab;
      });
    }

    if(this.state.dateRange.fromDate && this.state.dateRange.toDate && this.state.textSearch === ""){
      tableData = this.filterTableDataByDate(tableData);
    }

    return tableData;
  }

  getTabStatus = () => {
    return Ulti.mapDataTab[this.state.currentTab]
  }

  filterTableDataByDate = (tableData: CustomerListingInterface[]) => {
    let dateRange = this.state.dateRange
    const strFromDate = dateRange.fromDate;
    const strToDate = dateRange.toDate;
    const newTableData = tableData.filter((data) => {
      let compareDate = moment(data.date, "YYYY-MM-DD");
      let fromDate   = moment(strFromDate, this.dateFormat);
      let toDate     = moment(strToDate, this.dateFormat);
     return compareDate.isBetween(fromDate, toDate, null, '[]')
    });
    return newTableData
  };

  handleCustomertableData(CustomerArrayData:CustomerListingFromApi[]){
    const newData = CustomerArrayData.map((item: CustomerListingFromApi) => {
      const createdAtDate = new Date(item.attributes.created_at);
      const formattedDate = `${createdAtDate.getFullYear()}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getDate().toString().padStart(2, '0')}`;
      return {
        id: item.id,
        companyName: item.attributes.company_name,
        email: item.attributes.email,
        phoneNumber: item.attributes.phone_number,
        city: item.attributes.location_string,
        date: formattedDate,
        status:item.attributes.activated ? 'Active' : 'Inactive'
      }
    })
    this.setState({tableData: newData})
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguageCustomer(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await getStorageData("authToken")
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getCustomerList();
        });
      }
    }
    const editResponse = message?.properties?.RestAPIResponceSuccessMessage?.data
    if(editResponse && editResponse.id) {
     this.updateTableData(editResponse)
     this.setState({
      showToast: true,
      toastSuccess: "success",
      toastMessage: i18n.t('UPDATED_SUCCESSFULLY_TXT'),
     })
    }
    const resJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id && resJson.message === 'Account deleted successfully!'
    ) {
      this.removeItem(this.state.deleteId)
      this.onCloseDeleteModal();
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCustomerListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
      this.setState({
        totalCount: resJson?.meta?.pagination.total_count || 0,
        totalPages: resJson?.meta?.pagination.total_pages || 0
      });
      this.handleCustomertableData(resJson?.data ?? [])
    }
    // Customizable Area End
  }
  getCustomerList = (dateRange?: DateRange) => {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.CustomerListApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerListCallId = requestMessage.messageId;
    let fromDate = this.state.dateRange.fromDate;
    let toDate = this.state.dateRange.toDate;
    if (dateRange && (dateRange as {}).hasOwnProperty('toDate') && (dateRange as {}).hasOwnProperty('toDate')) {
      fromDate = dateRange?.fromDate;
      toDate = dateRange?.toDate;
    }

    const tabQueryString = this.state.currentTab ? `&activated=${this.state.currentTab === 1}` : ""
    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}` : ""

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CustomerListApiEndPoint + `&start_date=${fromDate}&end_date=${toDate}${tabQueryString}${searchQuery}&page=${this.state.page}&per_page=${this.state.rowsPerPage}` + this.state.sortType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.CustomerListGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openEditCustomer = (customer: CustomerListingInterface) => {
     this.setState({ isOpenEdit: true, customerDetail: customer })
  }
  closeEditCustomer = () => {
    this.setState({
      isOpenEdit: false,

    })
  }
  updateTableData = (newCustomer: CustomerListingFromApi) => {
    const newState = this.state.tableData.map((data: CustomerListingInterface) => {
      const resAttributes = newCustomer?.attributes
      const createdAtDate = moment(newCustomer.attributes.created_at).format('YYYY-MM-DD')
      return data.id === newCustomer?.id ? {
        id: newCustomer?.id,
        companyName: resAttributes?.company_name,
        email: resAttributes?.email,
        phoneNumber: resAttributes?.phone_number,
        city: resAttributes?.location_string,
        date: createdAtDate,
        status:resAttributes?.activated ? 'Active' : 'Inactive'
      } : data
    })
   this.setState({tableData: newState}, () => this.getCustomerList())
  }
  handleCloseSnackBars() {
    this.setState({showToast:false})
  }

  openDeleteCustomerModal = (deleteId: string) => {
  this.setState({ isOpenDeleteModal: true, deleteId: deleteId })
  }

  handleDelete = () => {
    if(this.state.deleteId){
      let deleteId = this.state.deleteId;
      const header = {
        token: this.state.apiToken,
        "Content-Type": configJSON.deleteCustomerApiContentType
      };
  
      const deleteCustomerMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteCustomerCallId = deleteCustomerMsg.messageId;
  
      deleteCustomerMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteCustomerAPIEndPoint}${deleteId}`
      );
  
      deleteCustomerMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      deleteCustomerMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteCustomerAPIMethod
      );
  
      runEngine.sendMessage(deleteCustomerMsg.id, deleteCustomerMsg);
    
    }
  }
  

  removeItem = (deleteId: string) => {
    let newData = this.state.tableData.filter((item: CustomerListingInterface) => item.id !== deleteId)

    this.setState({
      showToast: true,
      toastSuccess: "success",
      toastMessage: i18n.t('DELETED_SUCCESSFULLY_TXT'),
      tableData: newData,
    }, () => this.getCustomerList())
  }

  onCloseDeleteModal = () => {
    this.setState({
      isOpenDeleteModal: false,
      deleteId: '',
    })
  }
  handleSearchHeader = (value?: string) => {
    this.setState({ textSearch: value  || ""}, () => this.getCustomerList())
  }
  handleChangeLanguageCustomer = (lang: string) => {
    languageConvertor(lang);
    this.setState({
      language: lang,
      tabsData: [
        { label: i18n.t('ALLTXT') },
        { label: i18n.t('ACTIVETXT') },
        { label: i18n.t('INACTIVETXT') },
      ],
      customerColumns: [
          { id: 'companyName', label: i18n.t('COMPANY') },
          { id: 'email', label: i18n.t('EMAILTXT') },
          { id: 'phoneNumber', label: i18n.t('PHONENUMBERTXT') },
          { id: 'city', label: i18n.t('CITYTXT') },
          { id: 'status', label: i18n.t('STATUSTXT'), renderFunction: 'renderRowStatus' },
      ]
    })
  }
  recieveLanguageCustomer = (customerMessage: Message) => {
    if (customerMessage.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = customerMessage.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        })
      }
    }
  };
  getSortValueFn = (fieldName:string, sortType: string) => {
    this.setState({sortType: sortType })
  }
  exportEndPoint = () => {
    const {fromDate, toDate} = this.state.dateRange
    const tabQueryString = this.state.currentTab ? `&activated=${this.state.currentTab === 1}` : ""
    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}` : ""
    return  `${configJSON.CustomerListExportEndPoint}&start_date=${fromDate}&end_date=${toDate || fromDate}${tabQueryString}${searchQuery}`
  }
  handlePageChange = (newPage: number) => this.setState(({page: newPage}), () => this.getCustomerList())
  // Customizable Area End
}
