import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DataTable from "../../../components/src/DataTable.web";
import GenericTab from "../../../components/src/GenericTab.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import i18n from "i18next";
const TRANSACTION_LIST_SORTED_FIELD = [{ label: "Transaction Id", field: "transaction_id" }, { label: "Id", field: "order_id" }, { label: "Date", field: "date" }, { label: "Amount", field: "price"}, { label: "Status", field: "status"}]
import SortingTable from "../../../components/src/SortingTable.web";
import ImportExportData from "../../importexportdata/src/ImportExportData.web";
// Customizable Area End

import TransactionListController, { Props } from "./TransactionListController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class TransactionListBlock extends TransactionListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      // Required for all blocks
      <NavigationMenu id="" navigation={this.props.navigation} pageBackgroundMode="light" handleSearch={this.handleSearch}>
        <ThemeProvider theme={theme}>
          <TransactionListWrap>
            <Box className="transactionHeader">
              <Box className="titleWrap">
                <Box className="title">{i18n.t('TRANSACTIONTXT')}</Box>
                <Box id="transactionFound">
                  {this.state.totalCount} {i18n.t('TRANSACTIONTXT')} {i18n.t('FOUNDTXT')}
                </Box>
              </Box>
            </Box>

            <Box className="tabsWrap">
              <GenericTab
                currentTab={this.state.transactionCurrentTab}
                onTabChange={this.handleTabChange.bind(this)}
                tabsData={this.state.transactionTabsData}
              />
              <ImportExportData
                  navigation={this.props.navigation}
                  id=""
                  exportCsvEndPoint={this.exportEndPoint()}
                />
              <SortingTable columnSorted={TRANSACTION_LIST_SORTED_FIELD} getSortValueFn={this.getSortValueFn} />

              <Box className="dateRangeWrap">
                <Box className="dateRangeSize">
                  <CustomDateRangePicker
                    displayDateFormat="MMM DD"
                    markingType="period"
                    hidePlaceHolder2
                    current=""
                    markedDates={this.getMarkedDates(
                      this.state.transactionDateRange.fromDate,
                      this.state.transactionDateRange.toDate
                    )}
                    calenderContainer={{ left: this.state.language === "en" ? "-200px" : "-40px" }}
                    onDayPress={this.handleDateRangePickerDayPress}
                    onChange={this.handleDateRangePickerOnChange}
                  />
                </Box>
              </Box>
            </Box>

            <DataTable
              data={this.state.transactionTableData}
              columns={this.state.transactionTableColumns}
              rowsPerPage={this.state.transactionRowsPerPage}
              paginationData={{
                currentPage: this.state.page,
                totalPages: this.state.totalPages,
                totalItemCount: this.state.totalCount
              }}
              onPageChange={this.handlePageChange}
              hideContextMenu
            />
          </TransactionListWrap>
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

const TransactionListWrap = styled("div")({
  backgroundColor: 'white',
  paddingTop: '40px',
  paddingBottom: '30px',
  fontFamily: 'Roboto',
  "& .titleWrap": {
    display: "flex",
    fontSize: 25,
    marginTop: 4,
  },
  "& .title": {
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: '24px',
    letterSpacing: '2px'
  },
  "& .tabsWrap": {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    backgroundColor: '#fff',
    "@media (max-width: 420px)": {
      flexDirection:"column",
      padding: "16px 10px",
    },
  },
  "& .dateRangeWrap": {
    borderRadius: 8,
    padding: "0px 15px 0px 35px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
  },
  "& .dateRangeSize": {
    maxWidth: 150,
  },
  "& .transactionHeader": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  "& .btnAddTransaction": {
    display: 'flex',
    alignItems: 'center',
    background: '#2563EB',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '4px',
  },
  "& .btnAddTransactionText": {
    marginLeft: '4px',
  },
  "& table": {
    "& tbody .MuiTableCell-root.MuiTableCell-body": {
      height: 80
    }
  }
});