import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
const Ulti = require("./../utility");
export const configJSON = require("./config");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export type DateRange = {
  fromDate?: string;
  toDate?: string;
};

export interface ProductListingInterface {
  id: string;
  contact: string;
  name?: string;
  image?: string;
  category: string;
  manufacturer: string;
  quantity: string;
  keyword: string;
  price: string;
  date: string;
  status: string;
}

export interface AttributesInterface {
  categories: [];
  images: { url: string }[];
  created_at: string;
  keywords: string[];
  manufacturer_name: string;
  availability: string;
  name: string;
  stock_qty: string;
  price: string;
}

export interface RootDataInterface {
  id: string;
  attributes: AttributesInterface;
}
// Customizable Area End

export interface Props {
  navigation: object;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productRowsPerPage: number;
  productCurrentTab: number;
  productDateRange: DateRange;
  productTableData: ProductListingInterface[];
  productRowEdit: object | null;
  apiToken: string | null;
  productListArray: [];
  textSearch: string;
  productTabsData: {}[],
  productTableColumns: {}[],
  language: string,
  sortType: string
  page: number
  totalPages: number
  totalCount: number
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ProductListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  productData: {}[] = [];
  productTabsData: string[] = [];
  productColumns: string[] = [];
  productMapDataTab: string[] = [];
  productPagination: object = {};
  productDateFormat: string;
  getProductListCallId: string = "";
  deleteProductApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.productDateFormat = "YYYY-MM-DD";

    this.state = {
      productRowsPerPage: 10,
      productCurrentTab: 0,
      productDateRange: {
        fromDate: moment().startOf('week').format(this.productDateFormat),
        toDate: moment().endOf('week').format(this.productDateFormat),
      },
      productRowEdit: null,
      productTableData: [],
      apiToken: "",
      productListArray: [],
      textSearch: "",
      productTabsData: [],
      productTableColumns: [],
      language: "en",
      sortType: "",
      page: 1,
      totalPages: 0,
      totalCount: 0,
    };



    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateArray = [];
    if (!toDate) {
      dateArray = [fromDate];
    } else {
      let date = fromDate;
      const endDate = moment(toDate).add(1, "day").format(this.productDateFormat);
      while (date !== endDate) {
        dateArray.push(date);
        date = moment(date).add(1, "day").format(this.productDateFormat);
      }
    }
    let result: {}[] = [];
    dateArray.forEach((element, index) => {
      result[element as keyof object] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleTabChange = (value: number) => {
    this.setState({ productCurrentTab: value, page: 1 }, () => this.getProductList());
  };

  handleDateRangePickerDayPress = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.productDateRange;
    let newDateRange = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRange = { fromDate: dateString };
    } else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format(this.productDateFormat),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format(this.productDateFormat),
        };
    }
    this.setState({ productDateRange: newDateRange });
    if (!newDateRange.hasOwnProperty("toDate")) {
      newDateRange = {
        ...newDateRange,
        toDate: dateString
      }
    }
    this.getProductList(newDateRange);
  };

  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      productDateRange: data,
    });
  };

  getTabStatus = () => {
    return Ulti.productMapDataTab[this.state.productCurrentTab];
  };


  handleProductTableData(ProductArrayData: RootDataInterface[], metaData: {pagination: {total_pages: number; total_count: number}}) {
    let tableData: ProductListingInterface[] = []
    ProductArrayData.forEach((item: RootDataInterface) => {
      let category = '';
      if (item.attributes.categories.length > 0) {
        let names = item.attributes.categories.map((item: ProductListingInterface) => item.name);
        category = names.join(', ');
      }
      let image = '';
      if (item.attributes.categories.length > 0) {
        image = item.attributes.images?.[0].url;
      }

      const createdAtDate = new Date(item.attributes.created_at);
      const formattedDate = `${createdAtDate.getFullYear()}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getDate().toString().padStart(2, '0')}`;
      const keyWord = item.attributes.keywords ? item.attributes.keywords.join(', ') : '';

      tableData.push({
        id: item.id,
        contact: item.attributes.name,
        image: image,
        category: category,
        manufacturer: item.attributes.manufacturer_name,
        quantity: item.attributes.stock_qty,
        keyword: keyWord,
        price: `SAR ${item.attributes.price}`,
        date: formattedDate,
        status: item.attributes.availability == 'in_stock' ? 'In Stock' : 'Out of Stock'
      })
    });
    this.setState({
      productTableData: tableData,
      totalCount: metaData.pagination.total_count,
      totalPages: metaData.pagination.total_pages
    })
  }

  async componentDidMount() {
    this.getToken();
    super.componentDidMount();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageProduct(landing_lang)
  }
  
  componentDidUpdate(prevProps: Readonly<Props>, oldState: Readonly<S>): void {
    if (this.state.textSearch !== "" && this.state.textSearch !== oldState.textSearch) {
      this.getProductList()
    }
    if(oldState.language !== this.state.language){
      this.handleChangeLanguageProduct(this.state.language)
    }
    if(this.state.sortType !== oldState.sortType ){
      this.getProductList()
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguageProduct(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await getStorageData("authToken")
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getProductList();
        });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.receiveApiResponseMessage(message)
    }
    // Customizable Area End
  }

  receiveApiResponseMessage = (message: Message) => {
    const resJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (resJson?.errors) this.parseApiErrorResponse(resJson);
    if (this.getProductListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) && resJson.data) {
      this.handleProductTableData(resJson.data, resJson.meta)
    }
    if (this.deleteProductApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (resJson.message || resJson.error) this.showAlert("Message", resJson.message || resJson.error)
      if (resJson.message === configJSON.deleteProductSuccessMessage)
        this.getProductList()
    }
  }

  getProductList = (dateRange?: DateRange) => {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.productApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductListCallId = requestMessage.messageId;

    let fromDate = this.state.productDateRange.fromDate;
    let toDate = this.state.productDateRange.toDate;
    if (dateRange && (dateRange as {}).hasOwnProperty('toDate') && (dateRange as {}).hasOwnProperty('toDate')) {
      fromDate = dateRange?.fromDate;
      toDate = dateRange?.toDate;
    }
    const tabQuery = ["","&status=in_stock","&status=out_of_stock"][this.state.productCurrentTab]
    const productListAPIEndPoint = `${configJSON.productApiEndPoint}?${this.state.textSearch}start_date=${fromDate}&end_date=${toDate}${tabQuery}&page=${this.state.page}&per_page=${this.state.productRowsPerPage}` + this.state.sortType

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      productListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToAddEditProduct = (productId: string = "Add") => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Product');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      productId
    );
    this.send(message)
  }

  handleDeleteProduct = (productId: string) => {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.productApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productApiEndPoint}/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteProductApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleSearch = (value?: string) => {
    this.setState({ textSearch: "query=" + value + "&" })
  }

  handleChangeLanguageProduct = (lang: string) => {
    languageConvertor(lang);

    this.setState({
      language: lang,
      productTabsData: [
        { label: i18n.t('ALLPRODUCTSTXT') },
        { label: i18n.t('INSTOCKTXT') },
        { label: i18n.t('OUTOFSTOCKTXT') },
      ],
      productTableColumns: [
        { id: 'id', label: i18n.t('PRODUCTIDTXT'), renderFunction: 'renderRowId' },
        { id: 'name', label: i18n.t('PRODUCTNAMETXT'), renderFunction: 'renderRowHaveImage', imageColumn: 'image' },
        { id: 'category', label: i18n.t('CATEGORYTXT') },
        { id: 'manufacturer', label: i18n.t('MANUFACTURERTXT') },
        { id: 'quantity', label: i18n.t('QUANTITYTXT') },
        { id: 'keyword', label: i18n.t('KEYWORDTXT') },
        { id: 'price', label: i18n.t('PRICETXT') },
        { id: 'status', label: i18n.t('STATUSTXT'), renderFunction: 'renderRowStatus', mapStatusColumn: Ulti.productMapStatusColumn },
      ]
    })
  }
  recieveLanguageProduct = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        })
      }
    }
  };
  getSortValueFunction = (fieldName: string, sortType: string) => {
    this.setState({ sortType: sortType })
  }
  exportEndPoint = () => {
    const { fromDate, toDate } = this.state.productDateRange
    const tabQueryString = this.state.productCurrentTab ? `&status=${['in_stock', 'out_of_stock'][this.state.productCurrentTab - 1]}` : ""
    return `${configJSON.exportCsvEndPoint}?${this.state.textSearch}start_date=${fromDate}&end_date=${toDate || fromDate}${tabQueryString}`
  }
  handlePageChange = (newPage: number) => this.setState(({page: newPage}), () => this.getProductList())
  // Customizable Area End
}
