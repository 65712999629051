import React from "react";

// Customizable Area Start
import {
    Grid,
    Box,
    createTheme,
} from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import { Formik, FormikValues } from "formik";
import EditOrderVendorController, { Props } from "./EditOrderVendorController";
export const configJSON = require("./config");
import Select from "react-select";
import CustomModal from "../../../components/src/CustomModal.web";
import i18n from "i18next";
// Customizable Area End
export const initialFormValues = {
    vendorId: '',
    vendorName: '',
    contactPerson: "",
    email: '',
    phoneNumber: '',
    status: '',
    address:""
}
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

export const statusOptions = [{ label: "In Progress", value: "placed", color: "#F59E0B" }, { label: "Delivered", value: "delivered", color: "#059669" }, { label: "Cancelled", value: "cancelled", color: "#DC2626" }]

export default class EditOrderVendor extends EditOrderVendorController {
    constructor(props: Props) {
        super(props);
                // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        const {id,address,name,total,date,payment}=this.props.defaultVendor 
        return (
            // Customizable Area Start
            <>
                <CustomModal
                    data-testId="form-wrapper"
                    isVisible={this.props.isOpenModal}
                    title={i18n.t("ORDERDETAILSTXT")} 
                    onCloseModal={this.props.onCloseModal}
                    children={
                        <Formik
                            initialValues={this.state}
                            onSubmit={this.onEdit}
                            innerRef={this.formikRef}
                        >
                            {({
                                errors,
                                values,
                                touched,
                                handleBlur,
                                setFieldValue,
                                handleChange,
                                handleSubmit,
                            }: FormikValues) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3} className="form-body">
                                             <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.ORDERID")}
                                                    name="orderId"
                                                    placeholder="ID"
                                                    display="primary"
                                                    value={id}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={true}/>
                                            </Grid>
                                               <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.PRODUCTNAME")}
                                                    value={name}
                                                    display="primary"
                                                    placeholder="Name"
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onValueChange={handleChange}
                                                    disabled={true}/>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("ADDRESS")}
                                                    name="Address"
                                                    placeholder={i18n.t("ADDRESS")}
                                                    display="primary"
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={address}
                                                    disabled={true} />
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("PAYMENTTXT")} 
                                                    name="Payment"
                                                    placeholder={i18n.t("PAYMENTTXT")} 
                                                    display="primary"
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={payment}
                                                    disabled={true} />
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.DATE")}
                                                    name="date"
                                                    placeholder={i18n.t("DASHBOARD.TABLEHEADER.DATE")}
                                                    display="primary"
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={date}
                                                    disabled={true} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="total"
                                                    display="primary"
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.TOTAL")}
                                                    onBlur={handleBlur}
                                                    placeholder="Phone Number"
                                                    onValueChange={handleChange}
                                                    value={`${total}`} 
                                                    disabled={true}/>
                                            </Grid>
                                          
                                            <Grid item xs={12}>
                                                <Box className="label">{i18n.t("STATUSTXT")}</Box>
                                                <Box className={`${values.status.color}`}>   
                                                    <Select
                                                    id="status-vendor"
                                                    onBlur={handleBlur}
                                                    options={statusOptions}
                                                    onChange={option => setFieldValue("status", option?.value)}
                                                    name="status"
                                                    value={statusOptions.find((option) => option.label === values.status)}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            padding: "10px",
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            color: state.data.color, 
                                                        }),
                                                    }}
                                                   
                                                    components={{ IndicatorSeparator: null }}
                                                    menuPlacement="top"
                                                   
                                                />
                                                </Box>
                                            </Grid>
                                            
                                        </Grid>
                                        <Box className="footer">
                                            <CustomButton
                                                themes="dark"
                                                styles={{ background: "#E6EEFF", color: "#2563EB" }}
                                                width="132px"
                                                onClickHandle={this.props.onCloseModal}
                                                display="secondaryBorderless"
                                                dataTestId="cancel-vendor">
                                                {i18n.t("CANCELTXT")}
                                            </CustomButton>
                                            <CustomButton
                                                styles={{ background: "#2563EB" }}
                                                width="132px"
                                                onClickHandle={handleSubmit}
                                                themes="dark"
                                                display="primary"
                                                data-testid="save-vendor">
                                                {i18n.t("SAVETXT")}
                                            </CustomButton>
                                        </Box>
                                    </form>
                                );
                            }}
                        </Formik>
                    }
                />
            </>
            // Customizable Area End
        );
    }
}
