import React from "react";
import { Box, styled } from "@material-ui/core";
import Pagination, { PaginationProps } from "@material-ui/lab/Pagination";

type Props = {
    onPageChange: (newPage: number) => void
} & PaginationProps

export default class CustomPagination extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
    }
    render(): React.ReactNode {
        return (
            <PaginationWrapper>
                <Pagination
                    {...this.props}
                    onChange={(event, value) => this.props.onPageChange(value)}
                />
            </PaginationWrapper>
        )
    }
}

const PaginationWrapper = styled(Box)({
    marginTop: "24px",
    display: "flex",
    justifyContent: "center",
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: '#C1D3F9',
        color: '#2563EB'
    },
    "& .MuiPagination-ul": {
        "@media (max-width: 420px)": {
            flexWrap: "nowrap",
        },
    }
})