import React from "react";
import i18n from "i18next";
import CustomSelect from "./CustomSelect.web";
import { Box, styled } from "@material-ui/core";

type Range = "last_week" | "last_month" | "last_3_month" | "last_6_month" | "last_year"

type Props = {
    value: Range
    onChange: (newValue: Range) => void
}

export default class CustomRangeSelect extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const OPTIONS = [
            {
                name: i18n.t('LASTWEEKTXT'),
                key: "last_week"
            },
            {
                name: i18n.t('LASTMONTHTXT'),
                key: "last_month"
            },
            {
                name: i18n.t('LAST3MONTHSTXT'),
                key: "last_3_month"
            },
            {
                name: i18n.t('LAST6MONTHSTXT'),
                key: "last_6_month"
            },
            {
                name: i18n.t('LASTYEARTXT'),
                key: "last_year"
            },
        ]

        return (
            <StyledWrapper>
                <CustomSelect
                    className="custom-range-select"
                    options={OPTIONS}
                    value={this.props.value}
                    onChange={(event) => this.props.onChange(event.target.value as Range)}
                />
            </StyledWrapper>
        )
    }
}

const StyledWrapper = styled(Box)({
    "& .custom-range-select .MuiSelect-root": {
        minWidth: 120,
        paddingTop: 5,
        paddingBottom: 5,
        "& > div": {
            marginRight: 5
        }
    }
})