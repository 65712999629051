const resources = {
  addNewInventoryItem: {
    en: "Add New Item",
    ar: "تفاصيل المنتج",
  },
  productName: {
    label: {
      en: "Product Name",
      ar: "اسم المنتج",
    },
    error: {
      en: "Please enter your product name.",
      ar: "Please enter your product name.",
    },
  },
  category: {
    label: {
      en: "Category",
      ar: "الفئة",
    },
    error: {
      en: "Please enter your category name.",
      ar: "Please enter your category name.",
    },
  },
  price: {
    label: {
      en: "Price",
      ar: "السعر",
    },
    error: {
      en: "Please enter your price.",
      ar: "Please enter your price.",
    },
  },
  SKU: {
    label: {
      en: "SKU",
      ar: "SKU",
    },
    error: {
      en: "Please enter your SKU.",
      ar: "Please enter your SKU.",
    },
  },
  incomming: {
    label: {
      en: "Incomming",
      ar: "Incomming",
    },
    error: {
      en: "Please enter your Incomming.",
      ar: "Please enter your Incomming.",
    },
  },
  commited: {
    label: {
      en: "Committed",
      ar: "Committed",
    },
    error: {
      en: "Please enter your Committed.",
      ar: "Please enter your Committed.",
    },
  },
  available: {
    label: {
      en: "Available",
      ar: "Available",
    },
    error: {
      en: "Please enter your available.",
      ar: "Please enter your available.",
    },
  },
  status: {
    label: {
      en: "Status",
      ar: "الضمان",
    },
    error: {
      en: "Please enter your Status.",
      ar: "Please enter your Status.",
    },
  },
  image: {
    label: {
      en: "Image",
      ar: "الصورة",
    },
    error: {
      en: "Please upload image.",
      ar: "Please upload image.",
    },
  },
  addImage: {
    label: {
      en: "Add image",
      ar: "إضافة صورة",
    },
  },
  currency: {
    label: {
      en: "$",
      ar: "دولار\u200E"
    }
  }
};

export default resources;
