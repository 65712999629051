Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.loginAlertMsg = "Please login first";
exports.listOfOrdersEndPoints = "order_management/orders";
exports.listOfOrdersMethod = "GET";
exports.createOrderEndPoint = "order_management/orders";
exports.newOrderApiEndPoint = "bx_block_order_management/orders/new_order";
exports.createOrderItemInOrderEndPoint = "order_management/orders/:id/add_order_items";
exports.createOrderMethod = "POST";
exports.getOrderByIdEndPoint = "order_management/orders/:id";
exports.getOrderByIdMethod = "GET";
exports.deleteOrderItemEndPoint = "order_management/orders/:id/remove_order_items";
exports.deleteOrderItemMethod = "DELETE";
exports.appplyCouponCodeEndpoint = "order_management/orders/:id/apply_coupon";
exports.appplyCouponCodeMethod = "POST";
exports.getAddressListEndPoint = "order_management/addresses";
exports.getAddressListMethod = "GET";
exports.createAddressEntPoint = "order_management/addresses";
exports.createAddressMethod = "POST";
exports.addAddressToAddressEndPoint = "order_management/orders/:id/add_address_to_order";
exports.addAddressToAddressMethod = "PUT";
exports.deleteOrderEndPoint = "order_management/orders/:id";
exports.deleteOrderMethod = "DELETE";
exports.cencelOrderEndPoint = "order_management/orders/:id/cancel_order";
exports.cencelOrderMethod = "PUT";
exports.orderCreatedAlertMsg = "You order is created successfully!";
exports.orderNumberText = "Order Number";
exports.quantityText = "Quantity";
exports.orderedOnText = "Ordered On";
exports.cancelOrderText = "Cancel Order";
exports.deleteOrderText = "Delete Order";
exports.cancelledText = "Cancelled";
exports.descriptionText = "Description: ";
exports.categoryText = "Category: ";
exports.createNewOrderText = "Create New Order"
exports.createNewOrderItemText = "Create New Order Item"
exports.addNewOrderItem = "Add New Order Item";
exports.viewDetailBtn = "View Details";
exports.loadingText = "Loading...";
exports.cancelBtnLabel = "Cancel";
exports.createBtnLabel = "Create";
exports.deleteItemBtnLabel = "Delete this item";
exports.currencySymbole = "$";
exports.subTotalText = "Sub total";
exports.shippingChargeText = "Shipping Charges";
exports.totalTaxText = "Total Tax";
exports.discountText = "Discount";
exports.totalText = "Total";
exports.couponCodePlaceholder = "Please enter coupon code";
exports.applyBtnLabel = "Apply";
exports.addressLabel = "Address:";
exports.selectAddress = "Select Add";
exports.changeAddress = "Change Add";
exports.addressNameLabel = "Name";
exports.addressTypeLabel = "Address Type";
exports.flatNoLabel = "Flat no.";
exports.landmarkLabel = "Landmark";
exports.addressLabel = "Address";
exports.addressLine2Label = "Address Line 2";
exports.cityLabel = "City";
exports.stateLabel = "State";
exports.countryLabel = "Country";
exports.zipcodeLabel = "Zipcode";
exports.createNewAddressText = "Create New Address";
exports.addressTypePlaceholder = "Please enter address type*";
exports.namePlaceholder = "Please enter address name*";
exports.flatNoPlaceholder = "Please enter flat no.*";
exports.zipCodePlaceholder = "Please enter zipcode*";
exports.addressPlaceholder = "Please enter address*";
exports.addressLine2Placeholder = "Please enter address line 2";
exports.landmarkPlaceholder = "Please enter landmark";
exports.cityPlaceholder = "Please enter city";
exports.statePlaceholder = "Please enter state";
exports.countryPlaceholder = "Please enter country";
exports.phoneNoPlaceholder = "Please enter phone no*";
exports.selectThisAddressBtn = "Select this address";
exports.addressNavigationAlert = "Order ID not present";
exports.emptyOrderListMsg = "Order list is empty";
exports.catalogueIdPlaceholder = "Please enter catalogue id";
exports.catalogueVariantIdPlaceholder = "Please enter catalogue variant id";
exports.quantityPlaceholder = "Please enter quantity";
exports.createNewOrderTitle = "Create New Order";
exports.orderManagementLabel = "Order Management";

exports.cancelBtnLabel = "Cancel"
exports.saveBtnLabel = "Save"
exports.customerDetails = "Customer Details"
exports.vendorDetails = "Vendor Details"
exports.status = "Status"

exports.OrderListAdminContentType = "application/json";
exports.OrderListAdminMethod = "GET";
exports.OrderListAdminApiEndPoint = "/bx_block_order_management/orders/order_list";
exports.OrderListAdminExportApiEndPoint = "admin_order_list";

//Patch API

exports.editOrderListAdminContentType = "application/json";
exports.editOrderListAdminMethodType = "PATCH";
exports.editOrderListAdminAPiEndPoint = "bx_block_order_management/orders/update_order_by_admin?id=";
exports.editCustomerAPiMethod = "PATCH";
exports.editVendorParams = "?user_type=vendor"

exports.OrderListVendorContentType = "application/json";
exports.OrderListVendorMethod = "GET";
exports.OrderListVendorApiEndPoint = "bx_block_order_management/orders/order_items_vendor";
exports.OrderListVendorExportApiEndPoint = "vendor_order_list";

exports.editVendorOrderApiContentType = "application/json";
exports.editVendorOrderApiMethodType = "PATCH";
exports.editVendorOrderApiEndPoint = "bx_block_order_management/orders/update_order_status_by_vendor?id=";
exports.editVendorOrderApiParams = "&status=placed"

exports.deleteCustomerAPIContentType = "application/json";
exports.deleteCustomerAPIEndPoint = "account_block/accounts/destroy_account?id=";
exports.deleteCustomerAPIMethod = "DELETE";

exports.home = "Home";
exports.orders = "Orders";
exports.myOrders = "My Orders"
exports.getOrderListEndPoint = "bx_block_order_management/orders/customer_orders"
exports.getOrderListMethod = "GET"
exports.getOrderDetailEndPoint ="bx_block_order_management/orders/show_order_item?id="
exports.addressesApiEndpoint = "bx_block_order_management/addresses"
exports.getReorderListEndPoint = "bx_block_order_management/orders/reorder_list"
exports.orderNumber = "Order Number"
exports.datePlaced = "Date Placed"
exports.totalAmount = "Total Amount"
exports.viewOrder = "View Order"
exports.viewInvoice = "View Invoice"
exports.archiveOrder = "Archive Order"
exports.viewProduct = "View Product"
exports.buyAgain = "Buy Again"
exports.return = "Return"
exports.trackPackage = "Track Package"
exports.preparedForDispatch = "Prepared for Dispatch"
exports.deliveryBetween = "Delivery between"
exports.yourOrder = "Your Orders"
exports.searchOrder = "Search Orders"
exports.noOrder = "No Order Available!"
exports.initDay = "&days=30"
exports.orderDetail = "Order Detail"
exports.orderOn = "Ordered on"
exports.shippingAddress = "Shipping Address"
exports.paymentMethod = "Payment Method"
exports.orderSumary = "Order Summary"
exports.subTotal = "Item(s) Subtotal"
exports.shipping = "Shipping & Handling"
exports.beforeVAT = "Total Before VAT"
exports.estimatedVAT = "Estimated VAT"
exports.total = "Total"
exports.sar ="SAR"
exports.returnItems = "Return Item"
exports.showOrderItemEndPoint = "bx_block_order_management/orders/show_order_item?id="
exports.reasonEndPoint = "bx_block_settings/settings/return_order_reason_list"
exports.returnItemMethod = "PATCH"
exports.returnOrderApiEndPoint = "bx_block_order_management/orders/customer_return_request"
// Customizable Area End
