//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area Start
import {
  Box,
  styled
} from "@material-ui/core";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import Footer from "../../navigationmenu/src/Footer.web";
import Typography from "../../../components/src/design-system/Typography.web";
import Markup from "../../../components/src/design-system/Markup.web";
import Loader from "../../../components/src/Loader.web";
import i18next from "i18next";
import { TITLE_MAP } from "./utilities";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ContentManagementWrapper>
        <FrontHeader
          customStyle={{ background: "white", ...!this.state.token && { borderBottom: "solid 1px #DFDFDF" } }}
          navigation={this.props.navigation}
        />
        <Box className="container">
          <Typography size="xl" font="Roboto" weight="bold">
            {i18next.t(TITLE_MAP[this.state.contentId])}
          </Typography>
          {
            this.state.loading && <Loader loading />
          }
          <Box className="content" sx={{ gridTemplateColumns: this.state.imageData ? "0.25fr 0.75fr" : "1fr" }}>
            {
              this.state.imageData &&
              <Box className="content-image">
                <img src={this.state.imageData} />
              </Box>
            }
            {
              this.state.contentData &&
              <Markup classProps="content-description">{this.state.contentData}</Markup>
            }
          </Box>
        </Box>
        <Footer navigation={this.props.navigation} />
      </ContentManagementWrapper>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const ContentManagementWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "inherit",
  "& .container": {
    boxSizing: "border-box",
    padding: "24px 40px",
    maxWidth: 1440,
    margin: "30px auto 0",
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    "& > div:first-of-type": {
      textAlign: "center"
    },
    "& .content": {
      display: "flex",
      gap: "20px",
      maxWidth: "inherit",
      justifyContent: "center",
      "& > .content-image": {
        width: "25%",
        "& img": {
          width: "100%",
          objectFit: "contain"
        }
      },
      "& > .content-description": {
        maxWidth: "fit-content",
        flex: 1
      },
    }
  }
})
// Customizable Area End
