// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import moment from "moment";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
const Ulti = require("./../utility");
export const configJSON = require("./config");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export type DateRange = {
  fromDate?: string;
  toDate?: string;
};
export interface PayoutsListingInterface {
  id: number;
  transactionId: string;
  orderId: string;
  paymentMethod: string;
  amount: string;
  date: string;
  status: string;
}

export interface AttributesInterface {
  id: number
  order_management_order_id: number
  payment_status: string
  transaction_id: string
  total_price: string
  payment_method: string | null
  order_number: string
  payment_date: string
}

export interface RootDataInterface {
  id: string;
  attributes: AttributesInterface;
}

export interface Props {
  navigation: object;
  id: string;
}

interface S {
  payoutsRowsPerPage: number;
  payoutsCurrentTab: number;
  payoutsDateRange: DateRange;
  payoutsTableData: PayoutsListingInterface[];
  payoutsRowEdit: object | null;
  apiToken: string | null;
  payoutsListArray: [];
  textSearch: string;
  language: string;
  payoutsTabsData: {}[],
  payoutsTableColumns: {}[],
  sortType: string,
  page: number;
  totalPages: number;
  totalCount: number;
}

interface SS {
  id: string;
}

export default class PayoutsListController extends BlockComponent<Props, S, SS> {
  payoutsDateFormat: string = "YYYY-MM-DD";
  getPayoutsListCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      payoutsRowsPerPage: 10,
      payoutsCurrentTab: 0,
      payoutsDateRange: {
        fromDate: moment().startOf("week").format(this.payoutsDateFormat),
        toDate: moment().endOf("week").format(this.payoutsDateFormat),
      },
      payoutsRowEdit: null,
      language: 'en',
      payoutsTableData: [],
      apiToken: "",
      payoutsListArray: [],
      textSearch: "",
      payoutsTabsData: [
        { label: 'All' },
        { label: 'Completed' },
        { label: 'Pending' },
        { label: 'Failed' }
      ],
      payoutsTableColumns: [],
      sortType: "",
      page: 1,
      totalPages: 0,
      totalCount: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateArray: string[] = [];
    if (!toDate) {
      dateArray = [fromDate];
    } else {
      let date = fromDate;
      const endDate = moment(toDate).add(1, "day").format(this.payoutsDateFormat);
      while (date !== endDate) {
        dateArray.push(date);
        date = moment(date).add(1, "day").format(this.payoutsDateFormat);
      }
    }
    let result: { [key: string]: { color: string, startingDay?: boolean, endingDay?: boolean } } = {};
    dateArray.forEach((date, index) => {
      result[date] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleTabChange = (value: number) => {
    this.setState({ payoutsCurrentTab: value, page: 1 }, () => this.getPayoutList());
  };

  handleDateRangePickerDayPress = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.payoutsDateRange;
    let newDateRange = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRange = { fromDate: dateString };
    } else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format(this.payoutsDateFormat),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format(this.payoutsDateFormat),
        };
    }
    this.setState({ payoutsDateRange: newDateRange }, () => this.getPayoutList());
  };

  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      payoutsDateRange: data,
    }, () => this.getPayoutList());
  };

  async componentDidMount() {
    super.componentDidMount();
    const [language, token] = await Promise.all([getStorageData('language'), getStorageData("authToken")]);
    this.setState({ language: language || "en", apiToken: token }, () => {
      this.handleChangeLanguagePayouts(language)
      this.getPayoutList()
    })

  }

  componentDidUpdate(prevProps: Readonly<Props>, prev: Readonly<S>): void {
    if (prev.language !== this.state.language) {
      this.handleChangeLanguagePayouts(this.state.language)
    }
    if(this.state.sortType !== prev.sortType ){
      this.getPayoutList()
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.handleChangeLanguagePayouts(lang)
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getPayoutsListCallId) {
        this.setState({
          payoutsTableData: responseJson?.data?.map((item: RootDataInterface) => ({
            id: item.attributes.id,
            transactionId: item.attributes.transaction_id,
            orderId: item.attributes.order_number,
            paymentMethod: item.attributes.payment_method || "",
            amount: `SAR ${item.attributes.total_price}`,
            date: item.attributes.payment_date ? moment(item.attributes.payment_date).format("DD/MM/YY") : "",
            status: this.capitalizeFirstLetter(item.attributes.payment_status)
          })) || [],
          totalCount: responseJson?.meta?.pagination?.total_count || 0,
          totalPages: responseJson?.meta?.pagination?.total_pages || 0,
        })
      }
    }
  }

  handleChangeLanguagePayouts = (lang: string) => {
    languageConvertor(lang);

    this.setState({
      language: lang,
      payoutsTabsData: [
        { label: i18n.t('ALLTRANSACTIONSTXT') },
        { label: i18n.t('COMPLETEDTXT') },
        { label: i18n.t('PENDINGTXT') },
        { label: i18n.t('FAILEDTXT') },
      ],
      payoutsTableColumns: [
        { id: 'transactionId', label: i18n.t('TRANSACTIONIDTXT') },
        { id: 'orderId', label: i18n.t('DASHBOARD.TABLEHEADER.ORDERID') },
        { id: 'paymentMethod', label: i18n.t('PAYMENTMETHODTXT') },
        { id: 'date', label: i18n.t('DASHBOARD.TABLEHEADER.DATE') },
        { id: 'amount', label: i18n.t('AMOUNTTXT'), renderFunction: 'renderRowPrice' },
        { id: 'status', label: i18n.t('STATUSTXT'), renderFunction: 'renderRowStatus', mapStatusColumn: Ulti.transactionMapStatusColumn },
      ],
    })
  }

  getPayoutList = () => {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.transactionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPayoutsListCallId = requestMessage.messageId;

    const { fromDate, toDate } = this.state.payoutsDateRange
    const currentTab = Ulti.transactionTabsMap[this.state.payoutsCurrentTab]

    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}` : ""
    const tabQuery = currentTab !== "all" ? `&status=${currentTab}` : ""

    const apiEndPoint = `${configJSON.transactionListEndPoint}?start_date=${fromDate}&end_date=${toDate || fromDate}${searchQuery}${tabQuery}${this.state.sortType}&page=${this.state.page}&per_page=${this.state.payoutsRowsPerPage}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    this.send(requestMessage)
  }

  capitalizeFirstLetter = (inputString: string) =>  inputString ? inputString.charAt(0).toUpperCase() + inputString.slice(1) : "";
  
  handleSearch = (text?: string) => this.setState({textSearch: text || ""}, () => this.getPayoutList())
  getSortValueFn = (fieldName:string, sortType: string) => {
    this.setState({sortType: sortType })
  }

  getQueryString = () => {
    const { fromDate, toDate } = this.state.payoutsDateRange
    const currentTab = Ulti.transactionTabsMap[this.state.payoutsCurrentTab]
    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}` : ""
    const tabQuery = currentTab !== "all" ? `&status=${currentTab}` : ""
    return `?start_date=${fromDate}&end_date=${toDate || fromDate}${searchQuery}${tabQuery}`
  }

  exportEndPoint = () => configJSON.transactionListExportEndPoint + this.getQueryString()
  handlePageChange = (newPage: number) => this.setState(({page: newPage}), () => this.getPayoutList())
}
// Customizable Area End
