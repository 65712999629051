import React from "react";

// Customizable Area Start
import { Link } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  SvgIcon,
  TextField,
  InputAdornment,
  styled,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "../../../components/src/design-system/Typography.web"
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import SearchIcon from '@material-ui/icons/Search';
import { Avatar } from "@builder/component-library";
import TextInput from "../../../components/src/design-system/TextInput.web";
const assets = require("./assets")
import { SearchGrayIcon} from '../../../components/src/assets'
import LanguageSelector from '../../languageoptions/src/LanguageSelector.web';
import i18n from "i18next"
import CustomNotiDisplay from "../../../../packages/components/src/CustomeNotiDisplay.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    const { roleInfoData, roleType, avatar } = this.state
    const menuArray = roleInfoData || []
    const menuItems = this.getMenuItems(roleType, menuArray)
    const isAdmin = roleType === "admin"
    const vendorDashboardSearchOptions = [
      { label: 'Products', to: 'ProductList' },
      { label: 'Orders', to: 'OrderManagement' },
      { label: 'Inventory', to: 'InventoryManagement' },
      { label: 'Payouts', to: 'PayoutsList' },
    ]
    const pathName = window.location.pathname;
    const regex = /^\/vendordashboard.*/;

    return (
      <NavigationMenuWrapper className={this.props.pageBackgroundMode === "grey" ? " grey-mode" : ""}>
        <Box className="sidebar">
          <Drawer
            variant="permanent"
            anchor="left"
            className="drawerStyle"
            PaperProps={{
              className: "drawerPaperStyle",
              style: isAdmin ? {}
                : {
                  background: "#F9F9F9"
                }
            }}
          >
            <Typography>
              <Typography><Box className="logo">B2B Healthcare</Box></Typography>
            </Typography>
            <List className={"itemList"}>
              {
                menuItems.map(
                  (item) => {
                    const currentPath = window.location.pathname.toLowerCase()
                    const isActive = currentPath === item.path.toLowerCase() 
                    || item.groupId === this.props.id
                    const activeClassName = isActive ? "active-item" : ""
                    const additionalClassName = activeClassName && isAdmin ? " active-item-admin" : ""

                    return (
                      <ListItem
                        className={activeClassName + additionalClassName}
                        key={item.path}
                      >
                        <Link
                          to={item.path}
                        >
                          <ListItemIcon>
                            {
                              item.icon
                            }
                          </ListItemIcon>
                          <Typography><Box>{item.name}</Box></Typography>
                        </Link>
                      </ListItem>
                    )
                  }
                )
              }
            </List>
          </Drawer>
        </Box>
        <Box className={"content-wrapper"} style={this.props.wrapperStyle || {}}>
          <Box className="header" style={this.props.headerStyle || {}}>
          {regex.test(pathName) && <Autocomplete
              openOnFocus
              options={vendorDashboardSearchOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(option) =>
                <Box
                  display="flex"
                  gridGap={10}
                  alignItems="center"
                  padding="10px"
                  width="100%"
                  borderBottom="solid 1px #EEE"
                  margin="-6px 0"
                  className="searchLink"
                >
                  <Box data-test-id="vendor-search-link" onClick={() => {this.navigateToTarget(option.to)}} style={{ color: '#000', textDecoration: 'none', display: 'flex', alignContent: 'center' }}>
                  <SearchIcon /> {option.label}
                  </Box>
                </Box>
              }
                
              renderInput={(params) =>
                <TextField
                  {...params}
                  data-test-id="search-input"
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />}
              ListboxProps={{
                style: listBoxStyle
              }}
            />
          }
          {!regex.test(pathName) && 
            <form action="" onSubmit={this.handleSubmitForm}>
              <Box className='inputContainer'>
                <img src={SearchGrayIcon} className="searchImg" alt="" />
                <TextInput
                  placeholder={i18n.t("SEARCHTXT")}
                  value={this.state.textSearch}
                  onValueChange={this.handleTextSearch}
                  dataTestId='search'
                  id="search"
                />
              </Box>
            </form>
          }
            <Box display="flex" alignItems="center" gridGap={20}>
              <LanguageSelector />
              <CustomNotiDisplay numberOfNotti={this.state.numberOfUnreadNoti} onClickNotiFunction={() => navigation.navigate("Notifications", {})}/>
              <Popper placement="bottom-end" open={Boolean(this.state.profileMenuAnchorEl)} anchorEl={this.state.profileMenuAnchorEl}>
                <ClickAwayListener onClickAway={() => this.setState({ profileMenuAnchorEl: null })}>
                  <StyledMenu>
                    <CustomButton themes="primary" onClickHandle={() => navigation.navigate("VendorProfile", {})}>
                      {i18n.t("PROFILETXT")}
                    </CustomButton>
                    <CustomButton dataTestId="logout-btn" themes="primary" onClickHandle={this.handleLogout}>
                      {i18n.t("LOGOUTTXT")}
                    </CustomButton>
                  </StyledMenu>
                </ClickAwayListener>
              </Popper>

              <Box
                data-test-id="avatar"
                className="avatar"
                onClick={(event) => {
                  this.setState({ profileMenuAnchorEl: event.currentTarget })
                }}
              >
                <Avatar data-test-id="avatar-icon" image={avatar} onPress={() => { }} />
              </Box>
            </Box>
          </Box>
          <Box padding="10px 5px">
            {
              this.props.children
            }
          </Box>
        </Box>
        <Box className="raiseMessage" onClick={this.handleRaiseMessage}>{i18n.t("RAISETXT")}</Box>
      </NavigationMenuWrapper >
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const NavigationMenuWrapper = styled(Box)({
  background: "white",
  "&.grey-mode": {
    background: "#eee",
  },
  display: "grid",
  gridTemplateColumns: "280px 1fr",
  "& .sidebar": {
    width: 290,
    paddingRight: 6,
    "& .drawerStyle": {
      width: 'inherit',
      borderRadius: "0 32px 32px 0",
      position: "relative",
      height: "100vh",
      "&:before": {
        content: '""',
        position: "absolute",
        inset: 0,
        marginRight: -6,
        borderRadius: "inherit",
        background: "linear-gradient(178.23deg, #2563EB -3.38%, #43BCE2 103.63%)",
      }
    },
    "& .drawerPaperStyle": {
      width: "100%",
      background: "white",
      border: "none",
      borderRadius: "inherit",
      position: "relative",
      overflowY: "hidden",
    },
    "& .vendorSidebar": {
      background: "#F9F9F9",
    },
    "& .logo": {
      height: 72,
      background: "linear-gradient(90deg, #2563EB 0%, #43BDE1 100%)",
      fontFamily: "Roboto",
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 28,
      display: "flex",
      alignItems: "center",
      paddingLeft: 24,
      marginBottom: 16,
      "-webkit-text-fill-color": "transparent",
      backgroundClip: "text",
      transition: "all 0.3s",
    },
    "& .itemList": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      overflow: "auto",
      "& .MuiListItem-root": {
        paddingLeft: 12,
        height: 48,
        margin: "0 12px",
        width: "unset",
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        color: "#666666",
        textDecoration: "none",
      },
      "& .MuiListItemIcon-root": {
        minWidth: 40,
      },
      "& .MuiTypography-root": {
        lineHeight: "normal",
      }
    },
    "& .active-item": {
      "&.admin-active-item": {
        background: "#E6EEFF",
        borderRadius: "4px"
      },
      "& svg > path": {
        fill: "#2563EB"
      },
      "& > a": {
        color: "#2563EB !important"
      }
    },
  },
  "& > .content-wrapper": {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: "0px 24px",
    background: "inherit",
    "& > .header": {
      height: 72,
      position: "sticky",
      top: 0,
      zIndex: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "inherit",
      boxShadow:"0 8px 10px -3px rgba(0, 0, 0, 0.1)",
      "& > .MuiAutocomplete-root": {
        minWidth: "40%",
        background: "#eee",
        padding: 10,
        borderRadius:"5px",
        "& .MuiAutocomplete-endAdornment": {
          display: "none"
        }
      },
      "& .notification-icon": {
        cursor: "pointer"
      },
      "& .avatar": {
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.12)",
        borderRadius: "40px",
        cursor: "pointer",
        "& > div": {
          pointerEvents: "none"
        },
      },
      "& form": {
        margin: 0
      }
    }
  },
  "@media(max-width: 767px)":{
    gridTemplateColumns: "50px 1fr",
    "& .sidebar": {
      width: 50,
      paddingRight: 6,
      zIndex: 11,
      transition: "all 0.3s",
      "& .MuiBox-root.logo":{
        display: "block",
        height:32,
        fontSize: 9,
        lineHeight: "13px",
        paddingLeft: 0,
        marginBottom: 0,
        wordWrap: "break-word",
        textAlign: "center",
        paddingTop: 24,
      },
      "& .itemList":{
        "& li.MuiListItem-root":{
          paddingLeft:2,
          "& a":{
            "& .MuiBox-root":{
              display:'none'
            }
          }
        }
      },
      "&:hover":{
        width: 280,
        "& .MuiBox-root.logo":{
          height: 72,
          fontSize: 24,
          lineHeight: 28,
          display: "flex",
          paddingLeft: 24,
          marginBottom: 16,
        },
        "& .itemList":{
          "& li.MuiListItem-root":{
            paddingLeft:2,
            "& a":{
              "& .MuiBox-root":{
                display:'block'
              }
            }
          }
        }
      },
    },
    "& .content-wrapper":{
      "& > .header":{
        marginRight:-14,
        "& .MuiAutocomplete-root":{
          width: "60%"
        }
      }
    }
  },
  "& .inputContainer": {
    display:"flex",
    alignItems:"center",
    backgroundColor:"#eee",
    padding:"0 0 0 0.5rem",
    borderRadius:"4px",
    height: "48px",
    overflow:"hidden",
    "& #search": {
      borderColor:"transparent",
      minWidth:"500px",
      marginTop:"-0.5rem",
    },
    "& .searchImg": {
     marginBottom:"-0.2rem"
    },
    "& .PrivateNotchedOutline-root-9":{
      border:"none"
    },
    "& .MuiOutlinedInput-notchedOutline":{
      borderColor: "transparent !important"
    },
  
  },
 "& .raiseMessage": {
  display:"none",
 }
})

const StyledMenu = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: 1,
  paddingY: 0.5,
  width: 100,
  background: "white",
  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.12)",
  "& .MuiButtonBase-root": {
    background: "transparent",
    color: "black",
    border: 0,
    padding: 10,
  }
})

const listBoxStyle = {
  background: "#F5F5F5",
  borderRadius: 4,
  borderTop: "solid 3px white"
}
// Customizable Area End
