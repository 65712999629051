import React from "react";

// Customizable Area Start
import { Box, Link } from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DataTable from "../../../components/src/DataTable.web";
import GenericTab from "../../../components/src/GenericTab.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Typography from "../../../components/src/design-system/Typography.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ImportExportData from "../../importexportdata/src/ImportExportData.web";
import i18n from "i18next";
import SortingTable from "../../../components/src/SortingTable.web";
const PRODUCT_LIST_SORTED_FIELD = [{ label: "Id", field: "id" }, { label: "Name", field: "name" }, { label: "Manufacturer", field: "manufacturer_name" }, { label: "Stock quantity", field: "stock_qty"}, { label: "Keywords", field: "keywords"}, { label: "Price", field: "price"}]
// Customizable Area End

import ProductListController, { Props } from "./ProductListController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ProductListBlock extends ProductListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      // Required for all blocks
      <NavigationMenu id="" navigation={this.props.navigation} pageBackgroundMode="light" handleSearch={this.handleSearch}>
        <ThemeProvider theme={theme}>
          <ProductListWrap>
            <Box className="productHeader">
              <Box className="titleWrap">
                <Box className="title">{i18n.t('PRODUCTSTXT')}</Box>
                <Box id="productFound">
                  {this.state.totalCount} {i18n.t('PRODUCTSTXT')} {i18n.t('FOUNDTXT')}
                </Box>
              </Box>
              <CustomButton themes="primary" className="btnAddProduct" onClickHandle={() => this.navigateToAddEditProduct()}>
                <AddCircleOutlineIcon />
                <Typography>{i18n.t('ADDNEWITEMTXT')}</Typography>
              </CustomButton>
            </Box>

            <Box className="tabsWrap">
              <GenericTab
                currentTab={this.state.productCurrentTab}
                onTabChange={this.handleTabChange.bind(this)}
                tabsData={this.state.productTabsData}
              />
              <ImportExportData
                  navigation={this.props.navigation}
                  id=""
                  exportCsvEndPoint={this.exportEndPoint()}
                />
              <SortingTable columnSorted={PRODUCT_LIST_SORTED_FIELD} getSortValueFn={this.getSortValueFunction} />
              <Box className="dateRangeWrap">
                <Box className="dateRangeSize">
                  <CustomDateRangePicker
                    displayDateFormat="MMM DD"
                    markingType="period"
                    hidePlaceHolder2
                    current=""
                    markedDates={this.getMarkedDates(
                      this.state.productDateRange.fromDate,
                      this.state.productDateRange.toDate
                    )}
                    calenderContainer={{ left: "-200px" }}
                    onDayPress={this.handleDateRangePickerDayPress}
                    onChange={this.handleDateRangePickerOnChange}
                  />
                </Box>
              </Box>
            </Box>

            <DataTable
              data={this.state.productTableData}
              columns={this.state.productTableColumns}
              rowsPerPage={this.state.productRowsPerPage}
              onEditClick={(rowTable) => this.navigateToAddEditProduct(rowTable.id)}
              onDeleteClick={(productId) => this.handleDeleteProduct(productId)}
              paginationData={{
                currentPage: this.state.page,
                totalPages: this.state.totalPages,
                totalItemCount: this.state.totalCount
              }}
              onPageChange={this.handlePageChange}
            />
          </ProductListWrap>
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

const ProductListWrap = styled("div")({
  backgroundColor: '#FFFFFF',
  paddingTop: '40px',
  fontFamily: 'Roboto',
  paddingBottom: '30px',
  "& .titleWrap": {
    display: "flex",
    fontSize: 25,
    marginTop: 4,
  },
  "& .title": {
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: '24px',
    letterSpacing: '2px'
  },
  "& .tabsWrap": {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    "@media (max-width: 420px)": {
      flexDirection:"column",
      padding: "16px 10px",
    },
  },
  "& .dateRangeWrap": {
    borderRadius: 8,
    padding: "0px 15px 0px 35px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
  },
  "& .dateRangeSize": {
    maxWidth: 150,
  },
  "& .productHeader": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  "& .btnAddProduct": {
    display: 'flex',
    alignItems: 'center',
    background: '#2563EB',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '4px',
  },
  "& .btnAddProductText": {
    marginLeft: '4px',
  },
});