import React from "react";
import { getStorageData, setStorageData } from "../../../../../framework/src/Utilities";

const DEFAULT_LANGUAGE = "English";
const APP_LANGUAGE = "appLanguage";

export default class AppStateContextController extends React.Component {
  setLanguageToAsyncStorage = async (currentLanguage:string) => {
    await setStorageData(APP_LANGUAGE, currentLanguage);
    if(currentLanguage == "English"){
      await setStorageData("languename", "en");
    }else{
      await setStorageData("languename", "fr"); 
    }
    return null;
  }

  initLanguage = async () => {
    const currentLanguage = await getStorageData(APP_LANGUAGE);
    if(currentLanguage){  
      this.setLanguageToAsyncStorage(currentLanguage);
    }else{
      let localeCode = DEFAULT_LANGUAGE;
      this.setLanguageToAsyncStorage(localeCode);
    }
    return null;
  }
}
